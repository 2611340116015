import React,{useContext} from 'react';
import OrderCard from "../OrderCard";
import Information from "../Information";
import Context from "../../../context/Context";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FaRegFileAlt } from "react-icons/fa";
import Judge from '../../../svgs/Judge'
import Technical from '../../../svgs/Technical'
import Medical from '../../../svgs/Medical'
import Notice from '../../../svgs/Notice'
import Ledger from '../../../svgs/Ledger'
import Trust from '../../../svgs/Trust'

import MedIcon from '../../../svgs/MedIcon';
import Agreement from '../../../svgs/Agreement';

const DocumentType = props => {

  const context = useContext(Context);
  const {documentTypeName,disableDocumentType,baseLanguage, targetLanguage} = context.globalstate;
  return(
    <Row>
    <Col>
      <fieldset disabled={disableDocumentType}>
        <OrderCard title="დოკუმენტის ტიპი" number="2">
          <Information>
          შეგიძლიათ აირჩიოთ დოკუმენტის ტიპი (მაგ: სამედიცინო) ან კონკრეტული დოკუმენტი (მაგ: თანხმობა-ბავშვის გაყვანის თაობაზე,ამონაწერი-რეესტრიდან) და მიიღოთ მაქსიმალურად ზუსტი თარგმანი
          </Information>
          <Row>
            <Col>
              <button
                className={documentTypeName === 'სტანდარტული' ?'  grouped-btn highlight':'grouped-btn'}
                onClick={() => props.setDocType("1")}
              >
                <FaRegFileAlt className="icon fa-icon" />
                სტანდარტული
              </button>
            </Col>
            <Col>
              <button
                className={documentTypeName === 'იურიდიული' ?'  grouped-btn highlight':'grouped-btn'}
                onClick={() => props.setDocType("2")}
              >
                <Judge />
                იურიდიული
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <button disabled={baseLanguage === "ru" || targetLanguage === 'ru' ||
              baseLanguage === 'ua' || targetLanguage === 'ua'}
                className={documentTypeName === 'ტექნიკური' ?'  grouped-btn highlight':'grouped-btn'}
                onClick={() => props.setDocType("3")}
              >
                <Technical />
                ტექნიკური
              </button>
            </Col>
            <Col>
              <button
                className={documentTypeName === 'სამედიცინო' ?'  grouped-btn highlight':'grouped-btn'}
                onClick={() => props.setDocType("4")}
              >
                <Medical />
                სამედიცინო
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                className={documentTypeName === 'მინდობილობა' ?'  grouped-btn highlight grouped-btn-small':'grouped-btn grouped-btn-small'}
                onClick={() => props.setDocType("5")}
              >
                <Trust/>
                მინდობილობა
              </button>
            </Col>
            <Col>
              <button
                className={documentTypeName === 'თანხმობა' ?'  grouped-btn grouped-btn-small highlight':'grouped-btn grouped-btn-small'}
                onClick={() => props.setDocType("6")}
              >
                <Agreement/>
                თანხმობა
              </button>
            </Col>
            <Col>
              <button
                className={documentTypeName === 'ამონაწერი' ?'  grouped-btn grouped-btn-small highlight':'grouped-btn grouped-btn-small'}
                onClick={() => props.setDocType("7")}
              >
                <Ledger/>
                ამონაწერი
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                className={documentTypeName === 'მოწმობა/ცნობა' ?'  grouped-btn grouped-btn-small highlight':'grouped-btn grouped-btn-small'}
                onClick={() => props.setDocType("8")}
              >
                <Notice/>
                მოწმობა/ცნობა
              </button>
            </Col>
            <Col>
              <button
                className={documentTypeName === 'სამედიცინო ფორმები' ?'  grouped-btn grouped-btn-small highlight':'grouped-btn grouped-btn-small'}
                onClick={() => props.setDocType("9")}
              >
                <MedIcon/>
                სამედიცინო ფორმები
              </button>
            </Col>
          </Row>
        </OrderCard>
      </fieldset>
    </Col>
  </Row>
  )
}

export default DocumentType;