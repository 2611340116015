import React from 'react'
import sprite from '../svgs/sprite.svg'

const Ledger = () => {
    return (
        <svg className="icon" id="ledger-icon">
            <use href={sprite + "#ledger-icon"}/>
        </svg>
    )
}

export default Ledger
