import React from 'react';
import Gallery from './Gallery';
import AdsInfo from './AdsInfo';
import Container from "react-bootstrap/Container"


const Advertisement = () => {
  return(
    <Container>
      <h3 className="ads-heading">საბანერო რეკლამა (1000 ჩვენება - 0.60 ₾)</h3>
      <Gallery/>
      <AdsInfo/>
    </Container>

  )
}

export default Advertisement;