import React, { useState, useContext, useEffect } from "react";
import Context from '../../context/Context';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FaCheck } from 'react-icons/fa';


const PersonalInfoModal = () => {

    const [ show, setShow ] = useState(false);
    const context = useContext(Context);
    const { nameChangeHandler, emailChangeHandler, phoneChangeHandler, resetPersonalInfoHandler, additionalAddressChangeHandler } = context.handlers;
    const { additionalService, isProcessing, projectuid, formData } = context.globalstate;
    useEffect(() => {
        if (formData) {
            setShow(true);
        } else if (!projectuid) {
            setShow(false);
        }
    }, [ formData ]);


    const handleClose = (e) => {
        setShow(false);
    };

    return (
        <>
            <Modal show={show} onHide={() => {
                handleClose();
                resetPersonalInfoHandler();
            }}>
                <Modal.Header closeButton>
                    <p className="personal-modal-para">მიმდინარეობს დოკუმენტის ფასის დაანგარიშება. მანამდე შეგიძლიათ
                        შეიყვანოთ თქვენი მონაცემები</p>
                </Modal.Header>
                <Modal.Body>
                    <label>სახელი და გვარი</label>
                    <input
                        onChange={nameChangeHandler}
                        type="text"
                        className="modal-input"
                        placeholder="თქვენი სახელი და გვარი"
                    />
                    <label>თქვენი Email</label>
                    <input
                        onChange={emailChangeHandler}
                        type="email"
                        className="modal-input"
                        placeholder="თქვენი ელ. ფოსტა"
                    />
                    <label>ტელ. ნომერი</label>
                    <input
                        onChange={phoneChangeHandler}
                        type="tel"
                        className="modal-input"
                        placeholder="თქვენი მობილურის ნომერი"
                    />
                    {additionalService ? <>
                        <label>მისამართი (მაქს. 50 სიმბოლო)</label>
                        <input
                            type="text"
                            onChange={context.handlers.addressChangeHandler}
                            className="modal-input"
                            placeholder="თქვენი მისამართი"
                            maxLength={50}
                        />
                        <label>მისამართი (დამატებითი ინფორმაცია)</label>
                        <input
                            type="text"
                            onChange={context.handlers.additionalAddressChangeHandler}
                            className="modal-input"
                            placeholder="თქვენი მისამართი"
                            maxLength={50}
                        />
                    </> : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="confirm-btn" variant="primary" onClick={handleClose}>
                        დადასტურება <FaCheck/>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PersonalInfoModal;
