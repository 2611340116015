import React from 'react'
import sprite from '../svgs/sprite.svg'

const Notice = () => {
    return (
        <svg className="icon" id="notice-icon">
            <use href={sprite + "#notice-icon"}/>
        </svg>
    )
}

export default Notice
