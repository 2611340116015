import React,{useState,useContext} from "react";
import Context from '../../context/Context';
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"

const ErrorModal = () => {
  //eslint-disable-next-line
  const [show,setShow] = useState(false)
  const context = useContext(Context);
  const {closeErrorModalHandler} = context.handlers;
  const {showErrorModal} = context.globalstate;
  const handleClose = () => {setShow(false)};

  return (
    <>
      <Modal show={showErrorModal} onHide={handleClose}>
        <Modal.Body>
          <p className="lead">მოხდა ტექნიკური შეცდომა, სცადეთ თავიდან</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeErrorModalHandler}>
            დახურვა
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ErrorModal