import React from "react";

const Logo = () => {
  return (
    <a href="https://www.translate.ge/">
      <svg
        className="mx-3 my-1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 10120 2400"
        preserveAspectRatio="xMinYMin meet"
        version="1.1"
      >
        <title>translatege-logo-large</title>
        <desc>Created with Sketch.</desc>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="translatege-logo-large">
            <g id="layer101" fill="#787d88">
              <path
                d="M990,2383 C853,2358 701,2303 605,2245 C583,2232 558,2218 550,2214 C528,2203 436,2135 407,2107 C383,2085 381,2076 371,1912 C365,1818 360,1707 360,1665 L360,1590 L403,1590 C426,1589 506,1585 580,1580 L715,1571 L765,1619 C863,1711 931,1751 1049,1782 C1210,1824 1380,1795 1535,1697 C1546,1691 1575,1666 1600,1643 C1649,1597 1667,1591 1677,1618 C1685,1642 1746,1775 1776,1833 C1789,1860 1800,1887 1800,1893 C1800,1900 1807,1913 1815,1924 C1828,1942 1831,1941 1882,1917 C1946,1886 2076,1825 2184,1775 C2264,1738 2285,1736 2259,1767 C2250,1777 2239,1796 2232,1810 C2189,1901 2009,2103 1922,2157 C1907,2166 1883,2184 1868,2196 C1829,2228 1667,2308 1585,2337 C1412,2397 1167,2416 990,2383 Z"
                id="Path"
              />
              <path
                d="M184,1853 C166,1826 154,1806 133,1765 C128,1754 116,1731 107,1715 C79,1661 50,1577 25,1478 C3,1392 0,1361 0,1193 C0,1017 2,999 29,900 C66,767 82,724 125,643 C224,461 367,300 522,198 C642,118 804,44 871,38 C905,35 915,42 1072,196 L1238,358 L1106,494 C1034,569 959,639 940,649 C921,660 890,676 873,685 C855,695 840,706 840,711 C840,716 835,720 830,720 C816,720 720,816 720,829 C720,834 716,840 711,842 C695,848 650,930 624,1002 C601,1066 598,1090 597,1200 C597,1297 601,1335 615,1368 C625,1392 630,1414 626,1417 C618,1426 426,1438 296,1439 L197,1440 L204,1638 C207,1746 213,1845 216,1858 C225,1889 206,1886 184,1853 Z"
                id="Path"
              />
              <path
                d="M8675,1836 C8616,1816 8568,1793 8541,1772 C8513,1750 8517,1731 8559,1680 C8586,1648 8589,1646 8607,1662 C8642,1693 8726,1729 8789,1740 C8903,1760 9026,1723 9087,1651 C9120,1613 9157,1531 9163,1483 L9168,1442 L9121,1477 C9034,1545 8977,1564 8870,1565 C8786,1565 8769,1562 8724,1538 C8661,1505 8610,1448 8587,1383 C8544,1262 8587,1089 8687,985 C8839,826 9078,803 9212,934 C9252,973 9251,973 9265,903 L9272,870 L9337,870 C9399,870 9402,871 9397,893 C9389,928 9364,1063 9341,1195 C9292,1476 9269,1578 9245,1629 C9210,1700 9126,1783 9053,1817 C9000,1841 8983,1844 8855,1847 C8756,1849 8703,1846 8675,1836 Z M9032,1425 C9128,1380 9190,1308 9210,1218 C9234,1114 9192,1029 9095,984 C8943,913 8748,1022 8710,1199 C8700,1245 8700,1269 8709,1307 C8720,1350 8758,1410 8775,1410 C8779,1410 8793,1419 8807,1430 C8846,1461 8962,1458 9032,1425 Z"
                id="Shape"
                fillRule="nonzero"
              />
              <path
                d="M1866,1667 C1850,1633 1820,1567 1798,1520 C1756,1431 1756,1427 1784,1330 C1825,1186 1787,990 1691,850 C1657,801 1560,708 1524,690 C1508,682 1488,670 1480,664 C1454,643 1318,600 1278,600 C1254,600 1240,595 1240,587 C1240,580 1287,528 1345,470 C1403,412 1450,362 1450,358 C1450,350 1304,200 1297,200 C1291,200 1100,10 1100,4 C1100,2 1162,0 1238,0 C1422,0 1537,27 1720,112 C1897,195 2128,396 2204,535 C2213,552 2230,581 2242,600 C2272,649 2320,748 2320,761 C2320,767 2328,792 2339,818 C2403,976 2419,1238 2378,1427 L2356,1529 L2295,1556 C2262,1571 2195,1602 2145,1625 C2096,1648 2025,1682 1988,1699 C1951,1716 1915,1730 1908,1730 C1901,1730 1882,1702 1866,1667 Z"
                id="Path"
              />
              <path
                d="M3308,1619 C3249,1589 3235,1559 3236,1470 C3236,1401 3245,1344 3296,1080 C3316,975 3317,980 3259,980 L3208,980 L3214,943 C3227,870 3227,870 3280,870 C3335,870 3334,872 3350,764 C3371,626 3362,639 3433,642 C3471,644 3494,649 3493,656 C3492,662 3486,703 3481,746 C3475,789 3467,835 3464,848 C3458,870 3458,870 3575,870 L3693,870 L3686,901 C3683,918 3680,943 3680,956 C3680,980 3680,980 3562,980 L3444,980 L3423,1088 C3383,1293 3360,1425 3360,1451 C3360,1465 3370,1486 3383,1498 C3416,1530 3460,1535 3520,1515 C3548,1506 3573,1500 3575,1501 C3577,1503 3574,1528 3568,1557 C3558,1604 3554,1610 3519,1624 C3463,1647 3355,1644 3308,1619 Z"
                id="Path"
              />
              <path
                d="M4160,1613 C4096,1580 4051,1523 4043,1464 C4036,1407 4073,1304 4118,1257 C4203,1169 4347,1136 4528,1162 C4646,1179 4646,1179 4638,1105 C4624,987 4505,943 4330,991 C4206,1026 4214,1026 4206,990 C4195,933 4199,922 4233,906 C4327,862 4516,846 4613,875 C4674,893 4711,923 4739,976 C4766,1027 4767,1139 4741,1263 C4730,1314 4711,1415 4698,1488 L4675,1620 L4618,1620 L4560,1620 L4560,1586 L4560,1552 L4479,1594 C4404,1632 4391,1635 4306,1638 C4223,1641 4210,1639 4160,1613 Z M4460,1507 C4515,1481 4587,1406 4595,1368 C4598,1353 4603,1327 4606,1311 C4611,1282 4609,1281 4559,1266 C4486,1244 4363,1245 4303,1268 C4242,1291 4208,1323 4187,1378 C4172,1415 4172,1425 4184,1454 C4191,1472 4211,1496 4227,1508 C4276,1543 4385,1543 4460,1507 Z"
                id="Shape"
                fillRule="nonzero"
              />
              <path
                d="M5670,1621 C5606,1601 5524,1555 5517,1536 C5511,1520 5568,1440 5586,1440 C5592,1440 5609,1449 5623,1460 C5751,1561 5927,1552 5981,1443 C6014,1375 5987,1345 5851,1300 C5707,1252 5651,1217 5632,1161 C5616,1117 5617,1099 5636,1034 C5656,963 5720,900 5800,870 C5903,832 6083,856 6161,919 C6183,936 6183,937 6164,966 C6130,1018 6109,1034 6091,1022 C6026,976 5908,948 5847,964 C5816,971 5764,1019 5756,1047 C5738,1106 5771,1134 5925,1189 C6071,1242 6111,1278 6118,1365 C6126,1468 6050,1573 5932,1619 C5865,1646 5749,1646 5670,1621 Z"
                id="Path"
              />
              <path
                d="M6535,1616 C6468,1583 6447,1563 6427,1512 C6405,1457 6406,1427 6431,1353 C6483,1199 6657,1127 6898,1162 L7000,1176 L7000,1116 C6999,1051 6983,1022 6930,991 C6887,966 6793,966 6705,990 C6572,1026 6583,1026 6575,988 C6572,970 6567,948 6564,939 C6554,904 6718,860 6855,861 C6976,862 7032,882 7084,944 L7124,991 L7124,1093 C7124,1156 7116,1233 7103,1295 C7091,1350 7077,1429 7071,1471 C7064,1513 7055,1564 7049,1584 L7039,1620 L6979,1620 L6918,1620 L6925,1578 C6932,1539 6931,1537 6916,1553 C6907,1562 6869,1584 6832,1602 C6774,1630 6753,1635 6675,1638 C6596,1640 6579,1638 6535,1616 Z M6830,1507 C6887,1479 6948,1415 6960,1370 C6984,1277 6985,1282 6943,1270 C6922,1263 6865,1256 6815,1253 C6740,1249 6716,1252 6669,1269 C6639,1281 6602,1302 6588,1318 C6534,1375 6534,1464 6588,1505 C6639,1543 6754,1544 6830,1507 Z"
                id="Shape"
                fillRule="nonzero"
              />
              <path
                d="M7292,1619 C7252,1599 7232,1572 7215,1512 C7208,1489 7225,1371 7260,1190 C7270,1138 7282,1069 7285,1038 L7291,980 L7246,980 C7201,980 7200,979 7200,948 C7200,878 7207,870 7266,870 C7318,870 7320,869 7320,843 C7320,819 7326,789 7355,663 C7360,642 7366,640 7420,640 C7479,640 7480,641 7480,668 C7480,683 7471,732 7460,778 C7449,824 7440,863 7440,866 C7440,868 7494,870 7560,870 C7626,870 7680,872 7680,875 C7680,877 7675,902 7669,930 L7658,980 L7541,980 L7423,980 L7411,1058 C7404,1100 7390,1176 7380,1225 C7370,1275 7358,1352 7355,1396 C7348,1471 7349,1479 7369,1499 C7406,1536 7440,1537 7543,1503 C7556,1499 7560,1503 7560,1523 C7560,1586 7549,1608 7509,1624 C7451,1647 7345,1645 7292,1619 Z"
                id="Path"
              />
              <path
                d="M7792,1618 C7582,1535 7544,1240 7714,1017 C7756,962 7845,898 7913,872 C7997,840 8107,842 8175,875 C8240,906 8260,925 8295,983 C8319,1024 8324,1046 8328,1125 C8330,1176 8330,1234 8326,1254 L8319,1290 L8025,1290 L7730,1290 L7730,1318 C7731,1333 7739,1366 7748,1391 C7806,1548 7971,1576 8146,1458 C8169,1443 8193,1430 8198,1430 C8204,1430 8221,1446 8235,1465 L8262,1499 L8233,1525 C8190,1563 8111,1606 8051,1624 C7974,1647 7858,1644 7792,1618 Z M8205,1138 C8198,1068 8173,1024 8119,985 C8074,951 7986,950 7927,983 C7875,1011 7812,1074 7783,1127 C7740,1203 7732,1200 7986,1200 L8212,1200 L8205,1138 Z"
                id="Shape"
                fillRule="nonzero"
              />
              <path
                d="M9582,1617 C9467,1569 9408,1477 9402,1338 C9399,1263 9402,1239 9424,1175 C9470,1041 9551,947 9670,888 C9724,861 9749,855 9822,852 C9898,848 9914,851 9962,872 C10030,905 10062,933 10094,995 C10118,1041 10120,1055 10120,1167 L10120,1290 L9819,1290 L9517,1290 L9524,1336 C9545,1492 9692,1570 9847,1507 C9881,1493 9925,1469 9945,1454 C9986,1423 9999,1426 10034,1474 L10054,1501 L10025,1526 C9896,1633 9712,1671 9582,1617 Z M10000,1155 C10000,1080 9956,1004 9893,971 C9857,952 9772,958 9723,982 C9650,1018 9571,1109 9556,1173 L9550,1200 L9775,1200 L10000,1200 L10000,1155 Z"
                id="Shape"
                fillRule="nonzero"
              />
              <path
                d="M3635,1573 C3641,1522 3669,1363 3701,1195 C3711,1140 3722,1075 3725,1050 C3728,1025 3738,975 3746,938 L3762,870 L3822,870 L3883,870 L3876,924 C3873,954 3867,986 3863,996 C3859,1006 3880,991 3910,962 C3977,897 4056,858 4134,852 C4192,847 4193,848 4187,871 C4184,884 4178,914 4174,937 C4167,982 4166,983 4077,995 C4051,999 4019,1011 4006,1021 C3993,1032 3978,1040 3973,1040 C3962,1040 3880,1125 3880,1136 C3880,1140 3874,1151 3866,1159 C3847,1180 3800,1316 3800,1350 C3800,1364 3791,1416 3780,1466 C3769,1515 3760,1570 3760,1588 L3760,1620 L3694,1620 L3629,1620 L3635,1573 Z"
                id="Path"
              />
              <path
                d="M4795,1573 C4801,1522 4829,1363 4861,1195 C4871,1140 4882,1075 4885,1050 C4888,1025 4898,975 4906,938 L4922,870 L4982,870 L5043,870 L5038,915 L5032,960 L5075,926 C5138,874 5207,850 5293,850 C5442,851 5520,920 5531,1060 C5537,1124 5523,1217 5463,1538 L5447,1620 L5383,1620 C5339,1620 5320,1616 5320,1608 C5320,1601 5329,1558 5340,1512 C5351,1466 5360,1416 5360,1401 C5360,1386 5369,1334 5380,1284 C5420,1105 5402,1022 5316,981 C5260,953 5217,955 5151,985 C5047,1034 5010,1091 4980,1250 C4968,1313 4950,1409 4939,1463 C4929,1517 4920,1574 4920,1590 L4920,1620 L4854,1620 L4789,1620 L4795,1573 Z"
                id="Path"
              />
              <path
                d="M6174,1598 C6180,1575 6185,1547 6221,1335 C6232,1269 6250,1170 6260,1115 C6301,895 6313,823 6320,770 C6324,740 6335,680 6345,638 L6362,560 L6421,560 C6479,560 6480,561 6480,588 C6480,603 6471,658 6461,710 C6450,762 6432,861 6420,930 C6388,1118 6360,1274 6340,1385 C6330,1440 6319,1509 6315,1538 C6306,1620 6306,1620 6233,1620 C6170,1620 6168,1619 6174,1598 Z"
                id="Path"
              />
              <path
                d="M8305,1603 C8308,1593 8314,1557 8317,1523 L8323,1460 L8403,1460 C8480,1460 8482,1461 8477,1483 C8474,1495 8466,1531 8460,1563 L8449,1620 L8373,1620 C8306,1620 8299,1618 8305,1603 Z"
                id="Path"
              />
            </g>
            <g id="layer102" fill="#ED6A67">
              <path
                d="M990,2383 C853,2358 701,2303 605,2245 C583,2232 558,2218 550,2214 C528,2203 436,2135 407,2107 C383,2085 381,2076 371,1912 C365,1818 360,1707 360,1665 L360,1590 L403,1590 C426,1589 506,1585 580,1580 L715,1571 L765,1619 C863,1711 931,1751 1049,1782 C1210,1824 1380,1795 1535,1697 C1546,1691 1575,1666 1600,1643 C1649,1597 1667,1591 1677,1618 C1685,1642 1746,1775 1776,1833 C1789,1860 1800,1887 1800,1893 C1800,1900 1807,1913 1815,1924 C1828,1942 1831,1941 1882,1917 C1946,1886 2076,1825 2184,1775 C2264,1738 2285,1736 2259,1767 C2250,1777 2239,1796 2232,1810 C2189,1901 2009,2103 1922,2157 C1907,2166 1883,2184 1868,2196 C1829,2228 1667,2308 1585,2337 C1412,2397 1167,2416 990,2383 Z"
                id="Path"
              />
              <path
                d="M184,1853 C166,1826 154,1806 133,1765 C128,1754 116,1731 107,1715 C79,1661 50,1577 25,1478 C3,1392 0,1361 0,1193 C0,1017 2,999 29,900 C65,771 79,733 123,649 C246,414 447,217 670,112 C775,63 833,42 871,38 C905,35 915,42 1072,196 L1238,358 L1106,494 C1034,569 959,639 940,649 C921,660 890,676 873,685 C855,695 840,706 840,711 C840,716 835,720 830,720 C816,720 720,816 720,829 C720,834 716,840 711,842 C695,848 650,930 624,1002 C601,1066 598,1090 597,1200 C597,1297 601,1335 615,1368 C625,1392 630,1414 626,1417 C618,1426 426,1438 296,1439 L197,1440 L204,1638 C207,1746 213,1845 216,1858 C225,1889 206,1886 184,1853 Z"
                id="Path"
              />
              <path
                d="M1866,1667 C1850,1633 1820,1567 1798,1520 C1756,1431 1756,1427 1784,1330 C1825,1186 1787,990 1691,850 C1657,801 1560,708 1524,690 C1508,682 1488,670 1480,664 C1454,643 1318,600 1278,600 C1254,600 1240,595 1240,587 C1240,580 1287,528 1345,470 C1403,412 1450,362 1450,358 C1450,350 1304,200 1297,200 C1291,200 1100,10 1100,4 C1100,2 1162,0 1238,0 C1422,0 1537,27 1720,112 C1897,195 2128,396 2204,535 C2213,552 2230,581 2242,600 C2272,649 2320,748 2320,761 C2320,767 2328,792 2339,818 C2403,976 2419,1238 2378,1427 L2356,1529 L2295,1556 C2262,1571 2195,1602 2145,1625 C2096,1648 2025,1682 1988,1699 C1951,1716 1915,1730 1908,1730 C1901,1730 1882,1702 1866,1667 Z"
                id="Path"
              />
            </g>
            <g
              id="layer103"
              transform="translate(360.000000, 0.000000)"
              fill="#579FEC"
            >
              <path
                d="M710,2395 C702,2393 661,2386 620,2380 C497,2360 342,2305 245,2245 C223,2232 198,2218 190,2214 C168,2203 76,2135 47,2107 C23,2085 21,2076 11,1912 C5,1818 0,1707 0,1665 L0,1590 L43,1590 C66,1589 146,1585 220,1580 L355,1571 L405,1619 C503,1711 571,1751 689,1782 C850,1824 1020,1795 1175,1697 C1186,1691 1215,1666 1240,1643 C1265,1619 1290,1600 1295,1600 C1301,1600 1318,1628 1334,1663 C1351,1697 1381,1761 1402,1805 C1423,1848 1440,1889 1440,1895 C1440,1900 1447,1913 1455,1924 C1468,1942 1471,1941 1522,1917 C1586,1886 1716,1825 1824,1775 C1904,1738 1925,1736 1899,1767 C1890,1777 1878,1796 1872,1810 C1823,1908 1651,2101 1562,2157 C1547,2166 1523,2184 1508,2196 C1469,2228 1307,2308 1225,2337 C1113,2376 960,2400 836,2399 C775,2398 718,2396 710,2395 Z"
                id="Path"
              />
              <path
                d="M1506,1667 C1490,1633 1460,1567 1438,1520 L1398,1435 L1419,1354 C1464,1178 1433,999 1331,850 C1297,801 1200,708 1165,690 C1148,682 1128,670 1120,664 C1094,643 958,600 918,600 C894,600 880,595 880,587 C880,580 927,528 985,470 C1043,412 1090,362 1090,358 C1090,350 944,200 937,200 C930,200 740,10 740,3 C740,0 813,0 903,3 C1066,8 1136,21 1275,73 C1482,151 1754,371 1844,535 C1853,552 1870,581 1882,600 C1912,649 1960,748 1960,761 C1960,767 1969,792 1979,818 C2043,976 2059,1253 2016,1435 L1995,1525 L1945,1550 C1897,1574 1837,1602 1647,1689 C1598,1712 1553,1730 1547,1730 C1540,1730 1522,1702 1506,1667 Z"
                id="Path"
              />
            </g>
            <g
              id="layer104"
              transform="translate(1100.000000, 0.000000)"
              fill="#B8DE5A"
            >
              <path
                d="M1134,1756 C1157,1738 1170,1736 1170,1750 C1170,1756 1166,1760 1161,1760 C1156,1760 1143,1763 1133,1766 C1117,1771 1117,1770 1134,1756 Z"
                id="Path"
              />
              <path
                d="M766,1667 C750,1633 720,1567 698,1520 L658,1435 L679,1354 C724,1178 693,999 591,850 C557,801 460,708 425,690 C408,682 388,670 380,664 C354,643 218,600 178,600 C154,600 140,595 140,587 C140,580 187,528 245,470 C303,412 350,362 350,358 C350,350 204,200 197,200 C190,200 0,10 0,3 C0,0 73,0 163,3 C326,8 396,21 535,73 C742,151 1014,371 1104,535 C1113,552 1130,581 1142,600 C1172,649 1220,748 1220,761 C1220,767 1229,792 1239,818 C1303,976 1319,1253 1276,1435 L1255,1525 L1205,1550 C1157,1574 1097,1602 907,1689 C858,1712 813,1730 807,1730 C800,1730 782,1702 766,1667 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </svg>
    </a>
  );
};

export default Logo;
