import React from 'react'
import sprite from '../svgs/sprite.svg'

const Agreement = () => {
    return (
        <svg className="icon" id="agreement-icon">
            <use href={sprite + "#agreement-icon"}/>
        </svg>
    )
}

export default Agreement
