import React from "react";
import { Link } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
const NotFound = () => {
  const alertStyle = {
    color: "#ffffff",
    backgroundColor: "#ee706e",
    fontFamily: "nino-mtavruli",
    maxWidth: "35rem"
  };
  return (
    <div className="not-found text-center">
      <Alert style={alertStyle} className="mx-4">
        <Alert.Heading className="text-center heading-alert">
          შეცდომა, გვერდი ვერ მოიძებნა
        </Alert.Heading>
        <hr className="text-white" />
        <Link to="/">
          <button className="btn btn-blue center-btn">
            მთავარ გვერდზე დაბრუნება
          </button>
        </Link>
      </Alert>
    </div>
  );
};

export default NotFound;
