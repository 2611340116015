import React, { useRef, useState } from "react";
import Info from '../../svgs/Info'

import Overlay from "react-bootstrap/Overlay";
import Badge from "react-bootstrap/Badge";
import Tooltip from "react-bootstrap/Tooltip";

const Information = props => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <div className="card-information">
      <Badge className="info-badge"
        ref={target}
        onMouseOver={() => setShow(!show)}
        onMouseOut={() => setShow(!show)}
      >
        <Info/>
      </Badge>
      <Overlay target={target.current} show={show} placement={props.placement}>
        <Tooltip {...props} show={props.show}>
          {props.children}
        </Tooltip>
      </Overlay>
    </div>
  );
};

export default Information;