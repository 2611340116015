import React from "react";

const Technical = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512.0005"
        fill="currentColor"
        width="1.5em"
        className="icon"
      >
        <path d="m384.722656 63.640625 21.210938 21.210937-22.285156 22.289063c-29.105469-19.25-68.777344-16.078125-94.386719 9.53125-9.1875 9.1875-15.148438 20.269531-18.5625 31.945313l-193.023438 82.59375-36.347656-36.347657-21.214844 21.214844 27.601563 27.601563-13.078125 5.441406c-30.054688 14.027344-42.96875 49.792968-29.023438 79.734375l273.785157-118.382813c2.8125 4.335938 6.0625 8.464844 9.863281 12.265625 3.796875 3.796875 7.925781 7.050781 12.265625 9.863281l-118.421875 273.75c30.808593 14.335938 66.121093.128907 79.480469-28.453124l5.683593-13.664063 27.617188 27.613281 21.214843-21.210937-36.363281-36.363281 82.636719-192.96875c11.675781-3.414063 22.761719-9.375 31.953125-18.566407 25.609375-25.609375 28.78125-65.28125 9.535156-94.386719l22.285157-22.285156 21.214843 21.210938 63.636719-63.636719-63.640625-63.640625zm-42.429687 127.277344-21.210938-21.210938 21.210938-21.214843 21.214843 21.214843zm0 0"></path>
        <path d="m189.820312 300.929688-21.214843 21.214843-20.945313-20.949219-29.792968 12.636719 29.527343 29.523438-21.214843 21.214843 21.214843 21.210938 21.210938-21.210938 29.527343 29.527344 12.636719-29.792968-20.949219-20.949219 21.214844-21.210938zm0 0"></path>
      </svg>
    </>
  );
};

export default Technical;
