import React, { useContext, useState, useEffect, useRef } from "react";
import OrderCard from "../OrderCard";
import Information from "../Information";
import Context from "../../../context/Context";
import File from "../../../svgs/File";
import { Col } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { MdDelete,MdScanner } from "react-icons/md";
import { useToasts } from "react-toast-notifications";


const UploadFile = props => {
  const context = useContext(Context);
  const textareaRef = useRef(null);
  const { addToast } = useToasts();

  const {
    insertedText,
    fileToTranslate,
    baseLanguage,
    targetLanguage,
    projectuid,
    courierService,
    city,
    disableTextarea,
    notaryFileToTranslate,
  } = context.globalstate;
  const {
    clearFile,
    disableContinueBtnHandler,
    saveInsertedText,
    setDisableTextarea,
    clearInsertedText
  } = context.handlers;

  /*eslint-disable */
  // const [disableTextarea, setDisableTextarea] = useState(false);
  const [disableFileUpload, setDisableFileUpload] = useState(false);

  useEffect(() => {
    if (projectuid) {
      setDisableTextarea(true);
    }
  }, [projectuid]);

  /*eslint-disable */

  useEffect(() => {
    if (insertedText !== "") {
      props.fileInputRef.current.setAttribute("disabled", true);
    } else {
      props.fileInputRef.current.removeAttribute("disabled");
    }
  }, [insertedText]);
  /*eslint-disable */

  const stopSubmit = () => {
    props.fileInputRef.current.value = "";
    props.notaryFileInputRef.current.value="";

    if (!/safari/i.test(navigator.userAgent)) {
      props.fileInputRef.current.type = "";
      props.fileInputRef.current.type = "file";

      props.notaryFileInputRef.current.value="";
      props.notaryFileInputRef.current.value="file";

    }
  };

  const submitInsertedText = () => {
    if (!baseLanguage  || !targetLanguage) {
      addToast("გთხოვთ აირჩიოთ საბაზო და სათარგმნი ენები", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if (baseLanguage === targetLanguage) {
      addToast("საბაზო და სათარგმნი ენები არ შეიძლება ერთი და იგივე იყოს", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    if (courierService && !city ) {
      addToast("გთხოვთ აირჩიოთ ქალაქი", {
        appearance: "error",
        autoDismiss: true
      });
      return;
    }
    saveInsertedText();
  };

  return (
    <Row>
      <Col>
        <fieldset disabled={props.disabled}>
          <OrderCard title="დოკუმენტის ატვირთვა" number="6">
            <Information>
              ატვირთეთ სათარგმნი დოკუმენტი ან პირდაპირ ჩასვით ტექსტი
            </Information>
            <div className="upload-btn-wrapper">
              <button className="upload-btn">
                <File />
                <span className="file-placeholder uploaded-file-placeholder">
                  {fileToTranslate ? fileToTranslate : "ატვირთეთ ფაილი"}
                </span>
              </button>
              <input
                onClick={stopSubmit}
                type="file"
                className="uploadFile-input"
                onChange={props.uploadFile}
                onDrop={props.dropFile}
                ref={props.fileInputRef}
                accept="application/msword,.docx,application/vnd.ms-excel,.xlsx, text/plain,application/pdf"
                disabled={fileToTranslate ? true : false}
              />
            </div>

            {/* <span className="or">ან</span> */}
            {/* <textarea
              disabled={disableTextarea}
              ref={textareaRef.current}
              className="file-textarea"
              name="insertText"
              onChange={props.setInsertedText}
              onKeyUp={props.hideSVG}
              placeholder="ჩასვით ტექსტი"
            ></textarea> */}
            {/* <svg
              ref={props.textSVG}
              xmlns="http://www.w3.org/2000/svg"
              className="paste-text-icon"
              width="2.3rem"
              fill="#787d88"
              version="1.1"
              viewBox="-53 1 511 511.99906"
            >
              <g id="surface1">
                <path d="M 276.410156 3.957031 C 274.0625 1.484375 270.84375 0 267.507812 0 L 67.777344 0 C 30.921875 0 0.5 30.300781 0.5 67.152344 L 0.5 444.84375 C 0.5 481.699219 30.921875 512 67.777344 512 L 338.863281 512 C 375.71875 512 406.140625 481.699219 406.140625 444.84375 L 406.140625 144.941406 C 406.140625 141.726562 404.65625 138.636719 402.554688 136.285156 Z M 279.996094 43.65625 L 364.464844 132.328125 L 309.554688 132.328125 C 293.230469 132.328125 279.996094 119.21875 279.996094 102.894531 Z M 338.863281 487.265625 L 67.777344 487.265625 C 44.652344 487.265625 25.234375 468.097656 25.234375 444.84375 L 25.234375 67.152344 C 25.234375 44.027344 44.527344 24.734375 67.777344 24.734375 L 255.261719 24.734375 L 255.261719 102.894531 C 255.261719 132.945312 279.503906 157.0625 309.554688 157.0625 L 381.40625 157.0625 L 381.40625 444.84375 C 381.40625 468.097656 362.113281 487.265625 338.863281 487.265625 Z M 338.863281 487.265625 " />
                <path d="M 305.101562 401.933594 L 101.539062 401.933594 C 94.738281 401.933594 89.171875 407.496094 89.171875 414.300781 C 89.171875 421.101562 94.738281 426.667969 101.539062 426.667969 L 305.226562 426.667969 C 312.027344 426.667969 317.59375 421.101562 317.59375 414.300781 C 317.59375 407.496094 312.027344 401.933594 305.101562 401.933594 Z M 305.101562 401.933594 " />
                <path d="M 124.171875 228.914062 C 130.972656 228.914062 136.539062 223.351562 136.539062 216.546875 L 136.539062 201.582031 L 190.953125 201.582031 L 190.953125 330.203125 L 167.828125 330.203125 C 161.023438 330.203125 155.460938 335.769531 155.460938 342.570312 C 155.460938 349.371094 161.023438 354.9375 167.828125 354.9375 L 238.9375 354.9375 C 245.742188 354.9375 251.304688 349.371094 251.304688 342.570312 C 251.304688 335.769531 245.742188 330.203125 238.9375 330.203125 L 215.6875 330.203125 L 215.6875 201.585938 L 270.105469 201.585938 L 270.105469 216.550781 C 270.105469 223.351562 275.667969 228.917969 282.472656 228.917969 C 289.273438 228.917969 294.839844 223.351562 294.839844 216.550781 L 294.839844 188.847656 C 294.839844 182.042969 289.027344 176.851562 282.226562 176.851562 L 124.417969 176.851562 C 117.617188 176.851562 111.804688 182.042969 111.804688 188.847656 L 111.804688 216.550781 C 111.804688 223.351562 117.371094 228.914062 124.171875 228.914062 Z M 124.171875 228.914062 " />
              </g>
            </svg> */}
            {props.spinner ? (
              <Spinner
                className="spinner-sm"
                animation="border"
                variant="primary"
                size="sm"
              />
            ) : null}

            {insertedText !== "" ? (
              <button
                onClick={submitInsertedText}
                className="btn btn-blue btn-save-text"
              >
                შენახვა
              </button>
            ) : null}

            {fileToTranslate ? (
              <button
                onClick={() => {
                  clearFile();
                  clearInsertedText();
                  stopSubmit();
                  setDisableTextarea(false);
                  disableContinueBtnHandler();
                }}
                //onClick={clearFile}
                className="btn btn-delete-file"
              >
                წაშლა <MdDelete style={{ marginTop: "-3px" }} size="1.2rem" />
              </button>
            ) : null}



            {/* <div className="notray-file-container">
            <MdScanner className="notary-icon"/>
            <input
              className="notary-file-input"
              type="file"
              multiple
              ref={props.notaryFileInputRef}
              onClick={stopSubmit}
              onChange={props.uploadNotaryFile}
              disabled={notaryFileToTranslate ? true : false}
            />
            </div> */}


          </OrderCard>
        </fieldset>
      </Col>
    </Row>
  );
};

export default UploadFile;
