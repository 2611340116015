import React,{useContext} from "react";
import Context from '../../context/Context';
import { NavLink } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";


const Menu = () => {

const context = useContext(Context);

const {clearState} = context.handlers

  return (
    <nav className="header-menu">
      <ul>
        <li className="home">
          <NavLink onClick={clearState} to="/"exact activeStyle={{ color: "#569fec" }}>
            {" "}
            <AiFillHome />{" "}
          </NavLink>
        </li>
        <li>
          <NavLink to="/terms" exact activeStyle={{ color: "#569fec" }}>
            წესები და პირობები
          </NavLink>
        </li>
        <li>
          <NavLink to="/FAQ" exact activeStyle={{ color: "#569fec" }}>
            კითხვები
          </NavLink>
        </li>
        <li>
          <NavLink to="/Price" exact activeStyle={{ color: "#569fec" }}>
            ტარიფი
          </NavLink>
        </li>

        {/*<li>კონტაქტი: ელ-ფოსტა online@translate.ge , ტელეფონი: 568 43 53 50  </li>*/}
      </ul>
    </nav>
  );
};

export default Menu;
