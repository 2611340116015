import React from 'react';

const Background = (props) => {
  return(
    <main className = "background-layout clearfix">
      {props.children}
    </main>
  )
}

export default Background