import React from "react";
import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";

const Price = () => {
    return (
        <Container>
            <div className="terms">
                <Table striped bordered size="sm">
                    <thead>
                    <tr>
                        <th>ინგლისური</th>
                        <th>სტანდარტული</th>
                        <th>დაჩქარებული</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>იურიდიული</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>ტექნიკური</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>სამედიცინო (ქართ-ინგ)</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>სამედიცინო (ინგ-ქართ)</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    <tr>
                        <td>მინდობილობა</td>
                        <td>35</td>
                        <td>40</td>
                    </tr>
                    <tr>
                        <td>თანხმობა</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>ამონაწერი</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>მოწმობები/ცნობები</td>
                        <td>16</td>
                        <td>20</td>
                    </tr>
                    <tr>
                        <td>ფორმა 100</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    </tbody>
                </Table>
                <Table striped bordered size="sm">
                    <thead>
                    <tr>
                        <th>გერმანული</th>
                        <th>სტანდარტული</th>
                        <th>დაჩქარებული</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>იურიდიული</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    <tr>
                        <td>ტექნიკური</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    <tr>
                        <td>სამედიცინო</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    <tr>
                        <td>მინდობილობა</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    <tr>
                        <td>თანხმობა</td>
                        <td>35</td>
                        <td>40</td>
                    </tr>
                    <tr>
                        <td>ამონაწერი</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>მოწმობები/ცნობები</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>ფორმა 100</td>
                        <td>20</td>
                        <td>25</td>
                    </tr>
                    <tr>
                        <td>სტანდარტული</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    </tbody>
                </Table>
                <Table striped bordered size="sm">
                    <thead>
                    <tr>
                        <th>თურქული</th>
                        <th>სტანდარტული</th>
                        <th>დაჩქარებული</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>იურიდიული</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>ტექნიკური</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>სამედიცინო</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>მინდობილობა</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    <tr>
                        <td>თანხმობა</td>
                        <td>35</td>
                        <td>40</td>
                    </tr>
                    <tr>
                        <td>ამონაწერი</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>მოწმობები/ცნობები</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>ფორმა 100</td>
                        <td>20</td>
                        <td>25</td>
                    </tr>
                    <tr>
                        <td>სტანდარტული</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    </tbody>
                </Table>
                <Table striped bordered size="sm">
                    <thead>
                    <tr>
                        <th>უკრაინული</th>
                        <th>სტანდარტული</th>
                        <th>დაჩქარებული</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>იურიდიული</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>ტექნიკური</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>სამედიცინო</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>მინდობილობა</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    <tr>
                        <td>თანხმობა</td>
                        <td>35</td>
                        <td>40</td>
                    </tr>
                    <tr>
                        <td>ამონაწერი</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>მოწმობები/ცნობები</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>ფორმა 100</td>
                        <td>20</td>
                        <td>25</td>
                    </tr>
                    <tr>
                        <td>სტანდარტული</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    </tbody>
                </Table>
                <Table striped bordered size="sm">
                    <thead>
                    <tr>
                        <th>რუსული</th>
                        <th>სტანდარტული</th>
                        <th>დაჩქარებული</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>იურიდიული</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>ტექნიკური</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>სამედიცინო</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>მინდობილობა</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    <tr>
                        <td>თანხმობა</td>
                        <td>35</td>
                        <td>40</td>
                    </tr>
                    <tr>
                        <td>ამონაწერი</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>მოწმობები/ცნობები</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>ფორმა 100</td>
                        <td>20</td>
                        <td>25</td>
                    </tr>
                    <tr>
                        <td>სტანდარტული</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    </tbody>
                </Table>
                <Table striped bordered size="sm">
                    <thead>
                    <tr>
                        <th>ესპანური</th>
                        <th>სტანდარტული</th>
                        <th>დაჩქარებული</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>იურიდიული</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    <tr>
                        <td>ტექნიკური</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    <tr>
                        <td>სამედიცინო</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    <tr>
                        <td>მინდობილობა</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    <tr>
                        <td>თანხმობა</td>
                        <td>35</td>
                        <td>40</td>
                    </tr>
                    <tr>
                        <td>ამონაწერი</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>მოწმობები/ცნობები</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>ფორმა 100</td>
                        <td>20</td>
                        <td>25</td>
                    </tr>
                    <tr>
                        <td>სტანდარტული</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    </tbody>
                </Table>
                <Table striped bordered size="sm">
                    <thead>
                    <tr>
                        <th>ფრანგული</th>
                        <th>სტანდარტული</th>
                        <th>დაჩქარებული</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>იურიდიული</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    <tr>
                        <td>ტექნიკური</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    <tr>
                        <td>სამედიცინო</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    <tr>
                        <td>მინდობილობა</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    <tr>
                        <td>თანხმობა</td>
                        <td>35</td>
                        <td>40</td>
                    </tr>
                    <tr>
                        <td>ამონაწერი</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>მოწმობები/ცნობები</td>
                        <td>25</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>ფორმა 100</td>
                        <td>20</td>
                        <td>25</td>
                    </tr>
                    <tr>
                        <td>სტანდარტული</td>
                        <td>30</td>
                        <td>35</td>
                    </tr>
                    </tbody>
                </Table>
                <Table striped bordered size="sm">
                    <thead>
                    <tr>
                        <th>ჩინური</th>
                        <th>სტანდარტული</th>
                        <th>დაჩქარებული</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>იურიდიული</td>
                        <td>80</td>
                        <td>90</td>
                    </tr>
                    <tr>
                        <td>ტექნიკური</td>
                        <td>80</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>სამედიცინო</td>
                        <td>90</td>
                        <td>110</td>
                    </tr>
                    <tr>
                        <td>მინდობილობა</td>
                        <td>120</td>
                        <td>140</td>
                    </tr>
                    <tr>
                        <td>თანხმობა</td>
                        <td>70</td>
                        <td>90</td>
                    </tr>
                    <tr>
                        <td>ამონაწერი</td>
                        <td>80</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>მოწმობები/ცნობები</td>
                        <td>70</td>
                        <td>90</td>
                    </tr>
                    <tr>
                        <td>ფორმა 100</td>
                        <td>90</td>
                        <td>110</td>
                    </tr>
                    <tr>
                        <td>სტანდარტული</td>
                        <td>70</td>
                        <td>90</td>
                    </tr>
                    </tbody>
                </Table>
            </div>
        </Container>
    );
};

export default Price;
