import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import PersonalInfoModal from "./PersonalInfoModal";
import ErrorModal from "./ErrorModal";
import UploadFile from "./Cards/UploadFile";
import Languages from "./Cards/Languages";
import DocumentType from "./Cards/DocumentType";
import AdditionalServices from "./Cards/AdditionalServices";
import Duration from "./Cards/Duration";
import OrderInfo from "./OrderInfo";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import Context from "../../context/Context";
import LoadingOverlay from "react-loading-overlay";
import { useToasts } from "react-toast-notifications";
import Headline from "./Headline";
import CourierService from "./Cards/CourierService";
import { PDFDocument } from 'pdf-lib';


const API_URL = process.env.REACT_APP_API_URL;

const OrderPage = props => {
    /*eslint-disable */
    const [ file, setFile ] = useState("");
    const [ spinner, setSpinner ] = useState(false);
    const [ disabledCard, setDisabledCard ] = useState(false);
    const [ disabledForm, setDisabledForm ] = useState(false);
    /*eslint-disable */

    const context = useContext(Context);
    const { addToast } = useToasts();
    const history = useHistory();

    const {
        setInsertedText,
        saveInsertedText,
        setTargetLang,
        setDocType,
        setBaseLang,
        showErrorModalHandler,
        additionalServiceHandler,
        courierServiceHandler,
        passingDateHandler,
        setFormData,
        calculatePrice,
        setIsProcessing,
        setfileToTranslate,
        disableOrderCardsHandler,
        calculateNotaryPrice,
        setResponse

    } = context.handlers;

    const {
        isProcessing,
        baseLanguage,
        targetLanguage,
        additionalService,
        courierService,
        city,
        documentType,
        projectuid,
        disableOrderCards,
        passingDate,
        response,
    } = context.globalstate;

    const fileInputRef = useRef(null);
    const notaryFileInputRef = useRef(null);
    const checkboxRef = useRef(null);
    const checkboxCourier = useRef(null);
    const textSVG = useRef(null);
    const cardData = [ { file }, { spinner }, { disabledCard } ]; // ???


    useEffect(() => {
    }, [ cardData ]);

    useEffect(() => {
        disableOrderCardsHandler();
    }, [ projectuid ]);

    const readFile = (file) => {

        return new Promise((resolve, reject) => {

            const reader = new FileReader();

            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);

            reader.readAsArrayBuffer(file);
        });
    };

    const getNumPages = async (file) => {

        const arrayBuffer = await readFile(file);

        const pdf = await PDFDocument.load(arrayBuffer);

        return pdf.getPages();
    };

    const uploadFile = async () => {

        if (!baseLanguage || !targetLanguage) {

            addToast("გთხოვთ აირჩიოთ საბაზო და სათარგმნი ენები", {
                appearance: "error",
                autoDismiss: true
            });
            return;
        }
        if (baseLanguage === targetLanguage) {
            addToast("საბაზო და სათარგმნი ენები არ შეიძლება ერთი და იგივე იყოს", {
                appearance: "error",
                autoDismiss: true
            });
            return;
        }

        if (courierService && !city) {
            addToast("გთხოვთ აირჩიოთ ქალაქი", {
                appearance: "error",
                autoDismiss: true
            });
            return;
        }

        await setIsProcessing(true);
        let fileObject = fileInputRef.current.files[0];
        let formData = new FormData();
        if (fileObject.name.split('.').splice(-1)[0] === 'pdf' || fileObject.name.split('.').splice(-1)[0] === 'docx') {
            try {
                const pdfNumPages = await getNumPages(fileObject);
                formData.set("pdfNumPages", `${pdfNumPages.length}`);
            } catch (e) {
                setIsProcessing(false);
                showErrorModalHandler();
                return;
            }
        }

        console.log("fileObject::", fileObject);
        formData.append("fileToTranslate", fileObject);
        formData.set("baseLanguage", baseLanguage);
        formData.set("targetLanguage", targetLanguage);
        formData.set("additionalService", additionalService);
        formData.set("courierService", courierService);
        formData.set("city", city);
        formData.set("passingDate", passingDate);
        formData.set("documentType", documentType);
        await setFormData(formData); // provider

        try {
            const apiUri = `${API_URL}/directupload`;
            const { data } = await axios.post(apiUri, formData);
            // console.log(data)
            setResponse(data.response);
            await setfileToTranslate(fileObject.name);
            await calculatePrice();
        } catch (error) {
            setIsProcessing(false);
            showErrorModalHandler();

        } finally {
            await setIsProcessing(false);
        }
    };

    const uploadNotaryFile = async () => {

        // console.log('Start Notary File Upload .... ');

        if (baseLanguage === "" || targetLanguage === "") {
            addToast("გთხოვთ აირჩიოთ საბაზო და სათარგმნი ენები", {
                appearance: "error",
                autoDismiss: true
            });
            return;
        }
        if (baseLanguage === targetLanguage) {
            addToast("საბაზო და სათარგმნი ენები არ შეიძლება ერთი და იგივე იყოს", {
                appearance: "error",
                autoDismiss: true
            });
            return;
        }

        if (courierService && !city) {
            addToast("გთხოვთ აირჩიოთ ქალაქი", {
                appearance: "error",
                autoDismiss: true
            });
            return;
        }

        await setIsProcessing(true);
        let formData = new FormData();

        let fileObject = notaryFileInputRef.current.files;
        for (let i = 0; i < fileObject.length; i++) {
            formData.append("fileToTranslate", fileObject[i]);
        }

        formData.set("baseLanguage", baseLanguage);
        formData.set("targetLanguage", targetLanguage);
        formData.set("additionalService", additionalService);
        formData.set("courierService", courierService);
        formData.set("city", city);
        formData.set("passingDate", passingDate);
        formData.set("documentType", documentType);


        await setFormData(formData); // provider

        await calculateNotaryPrice();


    };

    const dropFile = e => {
        let fileObject = fileInputRef.current.files[0];
        fileObject = e.dataTransfer.files;
        e.preventDefault();
    };

    const hideSVG = e => {
        if (e.target.value !== "") {
            textSVG.current.classList.add("hide-visibility");
        } else {
            textSVG.current.classList.remove("hide-visibility");
        }
    };

    return (
        <section className="order-section">
            <LoadingOverlay
                active={isProcessing}
                spinner
                text="მიმდინარეობს ფასის გამოთვლა, გთხოვთ დაელოდოთ"
                styles={{
                    overlay: base => ({
                        ...base,
                        background: "rgba(216, 229, 242, 0.5)"
                    })
                }}
            >
                <PersonalInfoModal/>
                <ErrorModal/>
                {/* headline */}
                <Headline/>
                <Row className="order-section-row">
                    <Col lg={7}>
                        <fieldset disabled={disableOrderCards}>
                            <Languages
                                disabledForm={disabledForm}
                                setBaseLang={setBaseLang}
                                setTargetLang={setTargetLang}
                                baseLanguage={baseLanguage}
                                targetLanguage={targetLanguage}
                            />
                            <DocumentType
                                disabledForm={disabledForm}
                                setDocType={setDocType}
                            />

                            <AdditionalServices
                                disabledForm={disabledForm}
                                additionalServiceHandler={additionalServiceHandler}
                                checkboxRef={checkboxRef}
                                checkboxCourier={checkboxCourier}
                            />


                            <Duration
                                disabledForm={disabledForm}
                                passingDateHandler={passingDateHandler}
                            />

                            <CourierService
                                disabledForm={disabledForm}
                                courierServiceHandler={courierServiceHandler}
                                checkboxCourier={checkboxCourier}
                            />

                        </fieldset>

                        <UploadFile
                            disabled={disabledCard}
                            setInsertedText={setInsertedText}
                            saveInsertedText={saveInsertedText}
                            uploadFile={uploadFile}
                            fileInputRef={fileInputRef}
                            uploadNotaryFile={uploadNotaryFile}
                            notaryFileInputRef={notaryFileInputRef}
                            hideSVG={hideSVG}
                            textSVG={textSVG}
                            spinner={spinner}
                        />
                    </Col>

                    <Col lg={5}>
                        {spinner ? (
                            <Spinner
                                animation="border"
                                variant="primary"
                                className="file-spinner-big"
                            />
                        ) : null}
                        {/*TODO courier service*/}
                        <OrderInfo checkboxRef={checkboxRef} checkboxCourier={checkboxCourier}/>
                    </Col>
                </Row>
            </LoadingOverlay>
        </section>
    );
};

export default OrderPage;
