import React, { useState, useEffect } from "react";
import BannerThumbnail from "../../svgs/BannerThumbnail";
import Lightbox from "react-image-lightbox";
import AdsTable from "./AdsTable";
import {TOP_IMG,H1_IMG,H2_IMG,H3_IMG,H4_IMG,S1_IMG,M1_IMG} from "./GalleryImages";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

const images = [TOP_IMG, H1_IMG, H2_IMG, H3_IMG, H4_IMG, S1_IMG, M1_IMG];

const Gallery = () => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {}, [photoIndex]);

  const openLightBox = e => {
    setIsOpen(true);

    if (e.target.name === undefined) {
      e.target.name = e.target.closest("button").name;
    }

    switch (e.target.name) {
      case "TOP-img":
        setPhotoIndex(0);
        break;
      case "H1-img":
        setPhotoIndex(1);
        break;
      case "H2-img":
        setPhotoIndex(2);
        break;
      case "H3-img":
        setPhotoIndex(3);
        break;
      case "H4-img":
        setPhotoIndex(4);
        break;
      case "S1-img":
        setPhotoIndex(5);
        break;
      case "M1-img":
        setPhotoIndex(6);
        break;
      default:
         setPhotoIndex(0);
        break;
    }
  };
  const closeLightBox = () => {
    setIsOpen(false);
  };

  const prevPhoto = () => {
    setPhotoIndex((photoIndex + images.length - 1) % images.length);
  };
  const nextPhoto = () => {
    setPhotoIndex((photoIndex + 1) % images.length);
  };

  return (
    <div>
      <AdsTable>
        <button
          className="btn-sm btn-success"
          type="button"
          name="TOP-img"
          onClick={openLightBox}
        >
          <BannerThumbnail />
        </button>
        <button
          className="btn-sm btn-success"
          type="button"
          name="H1-img"
          onClick={openLightBox}
        >
          <BannerThumbnail />
        </button>
        <button
          className="btn-sm btn-success"
          type="button"
          name="H2-img"
          onClick={openLightBox}
        >
          <BannerThumbnail />
        </button>
        <button
          className="btn-sm btn-success"
          type="button"
          name="H3-img"
          onClick={openLightBox}
        >
          <BannerThumbnail />
        </button>
        <button
          className="btn-sm btn-success"
          type="button"
          name="H4-img"
          onClick={openLightBox}
        >
          <BannerThumbnail />
        </button>
        <button
          className="btn-sm btn-success"
          type="button"
          name="S1-img"
          onClick={openLightBox}
        >
          <BannerThumbnail />
        </button>
        <button
          className="btn-sm btn-success"
          type="button"
          name="M1-img"
          onClick={openLightBox}
        >
          <BannerThumbnail />
        </button>
      </AdsTable>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={closeLightBox}
          onMovePrevRequest={prevPhoto}
          onMoveNextRequest={nextPhoto}
        />
      )}
    </div>
  );
};
export default Gallery;
