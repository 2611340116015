import React from 'react'
import sprite from '../svgs/sprite.svg'

const MedIcon = () => {
    return (
        <svg className="icon" id="med-icon">
            <use href={sprite + "#med-icon"}/>
        </svg>
    )
}

export default MedIcon
