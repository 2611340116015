import React from "react";
import Table from "react-bootstrap/Table";
const AdsTable = props => {
  return (
    <div className="ads-table">

      {/* variant="success" */}
    <Table responsive >
        <thead>
          <tr>
            <th>ბანერი</th>
            <th>ზომა</th>
            <th>კოეფიციენტი</th>
            <th>მთავარი გვერდი</th>
            <th>შიდა გვერდი</th>
            <th>პოზიციის ნახვა</th>

          </tr>
        </thead>
        <tbody>
          <tr>
            <td>TOP</td>
            <td>1180 X 120 / 320 X 240</td>
            <td>4</td>
            <td>&#10003;</td>
            <td>&nbsp;</td>
            <td>{props.children[0]}</td>
          </tr>
          <tr>
            <td>H1</td>
            <td>1180 X 120 / 320 X 240</td>
            <td>2.2</td>
            <td>&#10003;</td>
            <td>&#10003;</td>
            <td>{props.children[1]}</td>
          </tr>
          <tr>
            <td>H2</td>
            <td>1180 X 120 / 320 X 240</td>
            <td>2.2</td>
            <td>&#10003;</td>
            <td>&#10003;</td>
            <td>{props.children[2]}</td>
          </tr>
          <tr>
            <td>H3</td>
            <td>1180 X 120 / 320 X 240</td>
            <td>2</td>
            <td>&#10003;</td>
            <td>&nbsp;</td>
            <td>{props.children[3]}</td>
          </tr>
          <tr>
            <td>H4</td>
            <td>1180 X 120 / 320 X 240</td>
            <td>1.8</td>
            <td>&#10003;</td>
            <td>&nbsp;</td>
            <td>{props.children[4]}</td>
          </tr>
          <tr>
            <td>S1</td>
            <td>1180 X 120 / 320 X 240</td>
            <td>2.5</td>
            <td>&#10003;</td>
            <td>&#10003;</td>
            <td>{props.children[5]}</td>
          </tr>
          <tr>
            <td>M1</td>
            <td>300 X 250 / 320 X 240</td>
            <td>2.8</td>
            <td>&nbsp;</td>
            <td>&#10003;</td>
            <td>{props.children[6]}</td>
          </tr>
        </tbody>
      </Table>

    </div>
  );
};

export default AdsTable;
