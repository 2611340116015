import React from "react";
import { Container } from "react-bootstrap";

const Terms = () => {
  return (
    <Container>
      <div className="terms">
        <h4>ყურადღებით წაიკითხეთ მომსახურების პირობები.</h4>
        <p>
          თუ თქვენ არ ეთანხმებით წინამდებარე მომსახურების პირობებს, მაშინ ნუ
          ისარგებლებთ ვებ-გვერდით:
          <a href="https://www.translate.ge/" target="_blank" rel="noopener  noreferrer">www.translate.ge</a>.
        </p>

        <h4>საჯარო ოფერტი</h4>

        <p>
          წინამდებარე ხელშეკრულება იდება ფიზიკურ პირს (შემდგომში „მყიდველი“,
          „მომხმარებელი“), რომელიც რეგისტრირდება საიტზე{" "}
          <a href="https://www.translate.ge/" target="_blank" rel="noopener  noreferrer">www.translate.ge</a>. (შემდგომში
          „ვებ-გვერდი“) და შპს „ტრანსლეიტ.ჯი“-ს (შემდგომში „შუამავალი“) შორის,
          რომელიც არის იმ პირის (შემდგომში „გამყიდველი) შუამავალი, რომელიც
          მითითებულია ამ ხელშეკრულების დანართში. შუამავალი მოქმედებს გამყიდველის
          სახელით.
        </p>
        <h4>ხელშეკრულება იდება შემდეგზე:</h4>
        <ol>
          <li>
            ტერმინები და განსაზღვრებები: a) საჯარო ოფერტი – შეთავაზება
            გამყიდველის მომსახურების/პროდუქტის შეძენის შესახებ, რომელიც
            განთავსებულია შუამავლის საიტზე და წარმოადგენს შეთავაზებას საიტის
            მომხმარებლებისადმი.
          </li>
          <li>
            {" "}
            ხელშეკრულების დადების სრულ და უპირობო თანხმობად (შემდგომში
            „აქცეპტი“) ითვლება შუამავლის საიტზე{" "}
            <a href="https://www.translate.ge/" target="_blank" rel="noopener  noreferrer">www.translate.ge</a>. -ზე
            რეგისტრაცია, შუამავლისათვის სათარგმნი ან/და ნოტარიულად დასამოწმებელი
            დოკუმენტის/ტექსტის გადაცემა და ვებ-გვერდზე მითითებული თანხის
            გადახდის განხორციელება.
          </li>
          <li>
            ხელშეკრულების აქცეპტი ნიშნავს, რომ მომხმარებელი/მყიდველი ეთანხმება
            შემოთავაზების ყველა პირობასა და განსაზღვრებას. აქცეპტი ხელშეკრულების
            (თავისი დანართებით) დადების ტოლფასია.
          </li>
          <li>
            ხელშეკრულების საგანს წარმოადგენს შუამავლისათვის გადაცემული
            დოკუმენტის/ტექსტის თარგმნა ან/და თარგმანის ნოტარიულად დამოწმება
            ვებ-გვერდზე მითითებული თანხის გადახდის სანაცვლოდ. აღნიშნული
            მომსახურებით/პროდუქტით უზრუნველყოფის სავარაუდო ვადაა ______.
          </li>
          <li>
            ხელშეკრულების დანართი წარმოადგენს მის განუყოფელ ნაწილს. ყველა
            პირობა, რომელიც დანართშია გაწერილი ხელშეკრულების პირობებს
            წარმოადგენს.
          </li>
          <li>
            {" "}
            ხელშეკრულების დანართ(ებ)ი განთავსდება ვებ–გვერდზე განყოფილებაში
            „თარჯიმნები“, რომელიც გამყიდველთა ჩამონათვალს მოიცავს.
          </li>
          <li>
            შუამავლის უფლებამოსილება შემოიფარგლება საიტზე გამყიდველის სახელით
            გარიგების საგნისა და პირობების საიტზე გამოქვეყნებით, გარიგების
            გაფორმებით, მისგან დოკუმენტის/ტექსტის მიღებით და მომხმარებლის მიერ
            ვებ-გვერდზე მითითებული თანხის მითითებულ ანგარიშზე ჩარიცხვის შემდეგ,
            მისთვის თარგმანის ან/და ნოტარიულად დამოწმებული თარგმანის გადაცემით.
            ხელშეკრულების მიხედვით, უფლებები და მოვალეობები წარმოიშობა მხოლოდ
            მომხმარებელსა და გამყიდველს შორის. ყველა პრეტენზია ხელშეკრულების
            საგანსა და მის პირობებზე წარედგინება გამყიდველს. შუამავალი
            გამყიდველის მიერ ხელშეკრულების შესრულებაზე პასუხისმგებელი არ არის.
            შუამავალი ასევე არ არის პასუხისმებელი თარჯიმნის კომპეტენტურობაზე,
            მის ცოდნასა თუ კვალიფიკაციაზე ან/და აღნიშნულის დამადასტურებლი
            დოკუმენტ(ებ)ის ნამდვილობასა თუ არსებობაზე და გაწეული მომსახურების/
            მიწოდებული პროდუქტის ხარისხზე.
          </li>
          <li>
            მომხმარებელი შუამავალის საანგარიშსწორებო ანგარიშზე საიტზე მითითებული
            შესაბამისი საშუალებებით რიცხავს თანხის იმ ოდენობას, რომელიც
            ნაჩვენებია ვებ-გვერდზე. თანხის გადარიცხვა შეიძლება ხდებოდეს უნაღდო
            ანგარიშსწორებით.
          </li>
          <li>თანხის გადახდის ყველა ხარჯს ანაზღაურებს მომხმარებელი.</li>
          <li>
            მომხმარებელი თანახმაა, შუამავალმა, მისი თანხმობის გარეშე, ჩაანაცვლოს
            გამყიდველი სხვა პირით, რომელიც უზრუნველყოფს მომხმარებელს
            შეთანხმებული მომსახურებით/პროდუქტით.
          </li>
          <li>
            მომხმარებელი ცნობს, ადატურებს და თანახმაა, რომ მომსახურება/პროდუქტი
            შესაძლოა დათქმულ ვადაში ვერ მიიღოს და ამის შესახებ მას პრეტენზია არ
            აქვს/ექნება.
          </li>
          <li>
            მომხმარებელი ცნობს, ადატურებს და თანახმაა, რომ ნებისმიერ დროს
            შეიძლება მიიღოს მომსახურებით/პროდუქტით უზრუნველყოფაზე უარი და
            ამასთან დაკავშირებით, მას პრეტენზიები არ აქვს.
          </li>
          <li>
            {" "}
            ხელშეკრულების დადების მტკიცებულებად ითვლება მომხმარებლის მიერ
            ვებ-გვერდზე რეგისტრაცია, შუამავლისათვის თანხის ჩარიცხვა და სათარგმნი
            ან/და ნოტარიულად დასამოწმებელი დოკუმენტის/ტექსტის შუამავლისათვის
            გადაცემა. სხვა რაიმე სახის მტკიცებულება იმისა, რომ ხელშეკრულება
            დადებულია, გამყიდველის მიერ არ განიხილება.
          </li>
          <li>
            შუამავალსა და გამყიდველს შორის ინფორმაციის გაცვლის წესი ქვეყნდება
            საიტზე და მომხმარებელი აქედან იგებს მის შესახებ.
          </li>
          <li>
            მომხმარებელი თანახმაა, რომ მისი პირადი მონაცემები, როგორიც არის ელ.
            ფოსტა, პირადი ნომერი, მობილური ტელეფონი, სახელი, გვარი და სხვა,
            შუამავალმა გაანდოს კომპანიას, რომლის მომსახურებას ან პროდუქციას
            მომხმარებელი შეიძენს საიტიდან.
          </li>
          <li>
            მომხმარებელი თანახმაა, რომ საიტზე რეგისტრაციის პროცედურის გავლის
            შემდეგ, მის ელექტრონულ მისამართზე შუამავალის მიერ გაიგზავნოს
            წერილები და შეტყობინებები, მათ შორის სარეკლამო ხასიათის.
            ამავდროულად, შუამავალი მომხმარებლის ელექტრონულ მისამართს და სხვა
            ინფორმაციას მესამე მხარეს არ გადასცემს.
          </li>
          <li>
            პირად პროფილში ტელეფონის ნომრის მითითებით, მომხმარებელი ავტომატურად
            ეთანხმება იმას, რომ მიიღოს მოკლე ტექსტური შეტყობინებები, მათ შორის
            სარეკლამო ხასიათის. ამ ფუნქციის გასაუქმებლად მომხმარებელმა უნდა
            შეავსოს მოთხოვნა შუამავალი საიტის ადმინისტრაციის სახელზე უკუკავშირის
            საშუალებით.
          </li>
          <li>
            მომხმარებელი თანახმაა, რომ ხელშეკრულების დადების, თანხის ჩარიცხვისა
            და შუამავლისათვის სათარგმნი ან/და ნოტარიულად დასამოწმებელი
            დოკუმენტის/ტექსტის გადაცემის შემდგომ, შეთანხმებული
            მომსახურების/პროდუქტის მიუღებლობის შემთხვევაში, ფულადი სახსრების
            დაბრუნება განხორციელდება შპს „ტრანსლაეიტ.ჯი“-ს მიერ, თუ რაიმე სხვა
            არ არის მითითებული შესაბამისი საჯარო ოფერტის პირობებში.
          </li>
          საიტზე მომხმარებლის ბალანსზე თანხის დაბრუნება ხდება სრულად.
          მომხმარებლის საბანკო ბარათზე თანხის დაბრუნება ხდება მომხმარებლის მიერ
          წერილობითი მოთხოვნისა და ყველა იმ დოკუმენტის ასლის წარდგენის შემდეგ,
          რაც საქართველოს კანონმდებლობითაა გათვალისწინებული.
          <li>
            მომხმარებელი თანახმაა, რომ იმ შემთხვევაში, როცა მან თანხა ჩარიცხა,
            სათარგმნი ან/და ნოტარიულად დასამოწმებელი დოკუმენტი/ტექსტი გადასცა
            შუამავალს და უკან გადაეცა ან/და შუამავლის ბრალეულობის გარეშე ვერ/არ
            გადაეცა თარგმანი ან/და ნოტარიულად დამოწმებული თარგმანი, ყველა
            პრეტენზია მიმართული იქნება გამყიდველისაკენ.
          </li>
        </ol>

        <h4>კონფიდენციალურობის პოლიტიკა</h4>

        <p>
          შპს „ტრანსლეიტ.ჯი“ უზრუნველყოფს ვებ-გვერდის{" "}
          <a href="https://www.translate.ge/" target="_blank" rel="noopener  noreferrer">www.translate.ge</a>. -ს
          მომხმარებლების კონფიდენციალურობის დაცვას.
        </p>

        <b>მონაცემების შენახვა და გამოყენება</b>
        <ul className="terms-ul">
          <li>მონაცემთა დამუშავება ხორციელდება სტატისტიკური ანალიზისთვის;</li>
          <li>
            გამოიყენება შესაძლო ინფორმაციული უსაფრთხოების ინციდენტების
            გამოვლენისა და ვებ ადმინისტრირების მიზნებისთვის;
          </li>
          <li>
            ვებ-გვერდზე მომხმარებელთა სტუმრობისას, სერვერზე ფიქსირდება
            ვებ-გვერდზე შესვლის თარიღი და მეთოდი, ინტერნეტპროტოკოლის მისამართი,
            რეფერალი და დათვალიერებული რესურსის მისამართი.
          </li>
        </ul>

        <p>ანალიტიკური სერვისები</p>
        <p>
          ვებ-გვერდის ხარისხის გაუმჯობესებისა და ტექნიკური შეფასების მიზნით, შპს
          “ტრანსლეიტ.ჯი“-ი სარგებლობს Google Analytics, Yandex Metrika, AdOcean,
          Facebook Pixel სერვისებით.
        </p>

        <p>„მზა ჩანაწერები“ (Cookies)</p>
        <p>
          {" "}
          ჩვენ ვებ-გვერდზე „მზა ჩანაწერები“ გამოიყენება მომხმარებლისათვის საიტზე
          ნავიგაციის გამარტივების, ინფორმაციის სასურველი ფორმატით შეთავაზების და
          ძებნის პარამეტრების გაუმჯობესების მიზნით, მზა ჩანაწერებში ინახება
          ონლაინ ჩატისთვის მომხმარებლის უნიკალური ნომერი და XSRF შეტევისგან
          თავდაცვისთვის მომხმარებლისთვის მინიჭებული უნიკალური ჰეში.
        </p>

        <p>სხვა ვებ-გვერდები</p>
        <p>
          შპს „ტრანსლეიტ.ჯი“-ს ვებ-გვერდი{" "}
          <a href="https://www.translate.ge/" target="_blank" rel="noopener  noreferrer">www.translate.ge</a>. შესაძლოა
          შეიცავდეს ინფორმაციას სხვა ვებ-გვერდების შესახებ. შპს „ტრანსლეიტ.ჯი“
          არ არის პასუხისმგებელი ასეთ ვებ-გვერდებზე არსებული კონფიდენციალურობის
          პოლიტიკაზე. სხვა ვებ-გვერდის სტუმრობისას შეგიძლიათ გაეცნოთ მასზე
          არსებულ კონფიდენციალურობის პოლიტიკას.
        </p>
      </div>
    </Container>
  );
};

export default Terms;
