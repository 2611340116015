import React from "react";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
const OrderCard = props => {
  let style = {
    maxWidth: "35rem",
    minWidth: "15rem",
    margin:'1rem',
    borderRadius:'0.6rem',
    borderColor:'#eee'
  };
  return (
    <div>
      <Card style={style} className="mx-auto order-card">

        <span className="blue-badge">{props.number}</span>

        <Card.Title className="card-title">{props.title}</Card.Title>
        <Col>
          <Card.Body>{props.children}</Card.Body>
        </Col>
      </Card>
    </div>
  );
};
export default OrderCard;
