import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Context from '../../context/Context';

const Footer = () => {

    const context = useContext(Context);
    const { scrollToTop } = context.handlers;
    return (
        <footer>

            <div className="info">
        <span>
          ელ-ფოსტა :
        <a href="mailto:online@translate.ge" target="blank">
          {" "}
            online@translate.ge
            &nbsp;
        </a>
          ტელეფონი: 577 65 71 86</span>
                &nbsp;&nbsp;
                <Link onClick={scrollToTop} to="/terms">წესები და პირობები</Link>

                <br/>



            </div>
        </footer>
    );
};

export default Footer;
