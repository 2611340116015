import React from "react";

const Headline = () => {
  return (
    <div className="headline">
      <h1 className="text-center landing-header">
        პროფესიონალი თარჯიმნის მომსახურება
      </h1>
      <p className="info-para landing-info">
      თარგმნეთ ნებისმიერი სირთულის დოკუმენტი პროფესიონალი თარჯიმნების დახმარებით და დაამოწმეთ ნოტარიულად სახლიდან გაუსვლელად
      </p>
    </div>
  );
};

export default Headline;
