import React from 'react';

const BannerThumbnail = () => {
  return(
    <>
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="11" viewBox="0 0 17 11"><g><g><path fill="#dee2e6" d="M3.517 1.522c.679 0 1.23.557 1.23 1.241s-.551 1.242-1.23 1.242c-.678 0-1.23-.557-1.23-1.242 0-.684.552-1.241 1.23-1.241zm0 3.178a1.93 1.93 0 0 0 1.92-1.937A1.93 1.93 0 0 0 3.518.826a1.93 1.93 0 0 0-1.92 1.937A1.93 1.93 0 0 0 3.517 4.7z"/></g><g><path fill="#dee2e6" d="M.414 10.913c.08 0 .162-.028.227-.087l5.626-4.995 3.552 3.583a.342.342 0 0 0 .488 0 .35.35 0 0 0 0-.492L8.649 7.25l3.166-3.497 3.883 3.59c.14.13.359.12.487-.02a.35.35 0 0 0-.021-.492l-4.138-3.826a.344.344 0 0 0-.488.022l-3.377 3.73-1.635-1.65a.343.343 0 0 0-.472-.014L.186 10.304a.35.35 0 0 0-.031.491.342.342 0 0 0 .259.118z"/></g><g><path fill="#dee2e6" d="M3.517 1.522c.679 0 1.23.557 1.23 1.241s-.551 1.242-1.23 1.242c-.678 0-1.23-.557-1.23-1.242 0-.684.552-1.241 1.23-1.241zm0 3.178a1.93 1.93 0 0 0 1.92-1.937A1.93 1.93 0 0 0 3.518.826a1.93 1.93 0 0 0-1.92 1.937A1.93 1.93 0 0 0 3.517 4.7z"/></g><g><path fill="#dee2e6" d="M.414 10.913c.08 0 .162-.028.227-.087l5.626-4.995 3.552 3.583a.342.342 0 0 0 .488 0 .35.35 0 0 0 0-.492L8.649 7.25l3.166-3.497 3.883 3.59c.14.13.359.12.487-.02a.35.35 0 0 0-.021-.492l-4.138-3.826a.344.344 0 0 0-.488.022l-3.377 3.73-1.635-1.65a.343.343 0 0 0-.472-.014L.186 10.304a.35.35 0 0 0-.031.491.342.342 0 0 0 .259.118z"/></g></g></svg>
    </>
  )

}

export default BannerThumbnail