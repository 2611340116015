import React from "react";

const Medical = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Capa_1"
        x="0px"
        y="0px"
        viewBox="0 0 180.586 180.586"
        fill="currentColor"
        width="1.5em"
        className="icon"
      >
        <path d="M158.121,91.413c0-13.733-11.175-24.906-24.91-24.906c-13.735,0-24.91,11.173-24.91,24.906  c0,11.124,7.329,20.565,17.41,23.756v24.946c0,14.044-11.426,25.471-25.471,25.471c-14.042,0-25.467-11.426-25.467-25.471V88.978  c21.146-3.581,37.307-22.016,37.307-44.165V7.5c0-4.142-3.357-7.5-7.5-7.5H85.928c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5  H97.08v29.813c0,16.435-13.371,29.805-29.807,29.805c-16.437,0-29.809-13.37-29.809-29.805V15h11.154c4.143,0,7.5-3.358,7.5-7.5  S52.762,0,48.619,0H29.965c-4.143,0-7.5,3.358-7.5,7.5v37.313c0,22.149,16.16,40.585,37.309,44.165v51.138  c0,22.315,18.153,40.471,40.467,40.471c22.315,0,40.471-18.155,40.471-40.471v-24.946  C150.792,111.979,158.121,102.537,158.121,91.413z M133.211,101.325c-5.465,0-9.91-4.447-9.91-9.912c0-5.462,4.445-9.906,9.91-9.906  c5.465,0,9.91,4.444,9.91,9.906C143.121,96.878,138.676,101.325,133.211,101.325z" />
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    </>
  );
};

export default Medical;
