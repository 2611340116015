import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

const Toggler = props => {
  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header className="toggler-header">
          <Accordion.Toggle
            as={Button}
            className="toggler-btn"
            variant="link"
            eventKey={props.eventKey}
          >
            {props.children[0]}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body className="toggler-body">
            {props.children[1]}
            {props.children[2]}
            {props.children[3]}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default Toggler;
