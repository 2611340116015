import React from "react";
import Menu from "./Menu";
import Logo from "../Logo";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const Header = () => {
  return (
    <header>
      <Row className="header-row">
        <Col lg="3">
          <Logo />
        </Col>
        <Col lg="9">
          <Menu />
        </Col>
      </Row>
    </header>
  );
};

export default Header;
