import React, { useContext, createRef } from "react";
import Context from "../../../context/Context";
import OrderCard from "../OrderCard";
import Information from "../Information";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const Languages = (props) => {
  const baseLanguageOptions = createRef();
  const targetLanguageOptions = createRef();
  const context = useContext(Context);

  const { setBaseLang, setTargetLang } = context.handlers;
  const { baseLanguage, targetLanguage } = context.globalstate;

  return (
    <Row>
      <Col>
        <fieldset disabled={props.disabledForm}>
          <OrderCard title="აირჩიეთ ენა" number="1">
            <Information placement="left">
              გთხოვთ, მონიშნოთ, რომელი ენიდან რომელ ენაზე გსურთ დოკუმენტის
              გადათარგმნა
            </Information>
            <Row>
              <label>რომელი ენიდან</label>

              <select
                onChange={setBaseLang}
                ref={baseLanguageOptions}
                className="order-select mx-auto my-2"
              >
                <option defaultValue value="">
                  აირჩიეთ საბაზო ენა
                </option>
                <option value="en" selected={baseLanguage === "en"}>
                  ინგლისური
                </option>
                <option value="ka" selected={baseLanguage === "ka"}>
                  ქართული
                </option>
                <option  value="ru" selected={baseLanguage === "ru"}>
                  რუსული
                </option>
                <option value="tr" selected={baseLanguage === "tr"}>
                  თურქული
                </option>
                <option value="es" selected={baseLanguage === "es"}>
                  ესპანური
                </option>
                <option value="fr" selected={baseLanguage === "fr"}>
                  ფრანგული
                </option>
                <option value="ua" selected={baseLanguage === "ua"}>
                  უკრაინული
                </option>
                <option value="de" selected={baseLanguage === "de"}>
                  გერმანული
                </option>
                <option value="zh" selected={baseLanguage === "zh"}>
                  ჩინური
                </option>
                <option value="pt" selected={baseLanguage === "pt"}>
                  პორტუგალიური
                </option>
              </select>
            </Row>
            <Row>
              <label>რომელ ენაზე</label>
              <select
                onChange={setTargetLang}
                ref={targetLanguageOptions}
                className="order-select mx-auto my-2"
              >
                <option defaultValue value="">
                  აირჩიეთ სათარგმნი ენა
                </option>

                <option  value="en" selected={targetLanguage === "en"}>
                  ინგლისური
                </option>
                <option  value="ka" selected={targetLanguage === "ka"}>
                  ქართული
                </option>
                <option  value="ru" selected={targetLanguage === "ru"}>
                  რუსული
                </option>
                <option  value="tr" selected={targetLanguage === "tr"}>
                  თურქული
                </option>
                <option value="es" selected={targetLanguage === "es"}>
                  ესპანური
                </option>
                <option value="fr" selected={targetLanguage === "fr"}>
                  ფრანგული
                </option>
                <option  value="ua" selected={targetLanguage === "ua"}>
                  უკრაინული
                </option>
                <option value="de" selected={targetLanguage === "de"}>
                  გერმანული
                </option>
                <option value="zh" selected={targetLanguage === "zh"}>
                  ჩინური
                </option>
                <option value="pt" selected={targetLanguage === "pt"}>
                  პორტუგალიური
                </option>
              </select>
            </Row>
          </OrderCard>
        </fieldset>
      </Col>
    </Row>
  );
};

export default Languages;
