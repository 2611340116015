import React from "react";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Toggler from "./Toggler";
import priceImg from '../../images/pricePic.jpg'; // with import


const FAQ = () => {
    return (
        <Container className="FAQ">
            <h3 className="FAQ-h3">ხშირად დასმული კითხვები</h3>
            <Toggler eventKey={1}>
                <h4>ვინ უზრუნველყოფს დოკუმენტის თარგმნას?</h4>
                <p>
                    Online.translate.ge თანამშრომლობს პროფესიონალ თარჯიმნებთან სხვადასხვა ენების მიმართულებით.
                    დოკუმენტის თარგმნა არ ხორციელდება მანქანურად, შესაბამისად ის არის ბევრად უფრო ზუსტი, კვალიფიციური და
                    ენობრივად გამართული.
                </p>
            </Toggler>
            <Toggler eventKey={1}>
                <h4>
                    შესაძლებელია თუ არა სხვადასხვა სახისა და სირთულის დოკუმენტების
                    თარგმნა?
                </h4>
                <p>
                    დიახ, ჩვენი გამოცდილი თარჯიმნები უზრუნველყოფენ სხვადასხვა სირთულის ტექსტებისა და დოკუმენტების
                    თარგმნას. ესენია: ტექნიკური, იურიდიული, სამედიცინო, ფინანსური დოკუმენტები.
                </p>
            </Toggler>
            <Toggler eventKey={2}>
                <h4>მოწმდება თუ არა თარგმნილი დოკუმენტების ხარისხი?</h4>
                <p>
                    დიახ, Translate.ge-ს გარდა პროფესიონალი თარჯიმნებისა, ჰყავს ე.წ. კორექტორების გუნდი, რომლებიც კიდევ
                    ერთხელ ამოწმებენ თარგმნილი დოკუმენტის სისწორესა და ხარისხს.
                </p>
            </Toggler>
            <Toggler eventKey={3}>
                <h4>როგორ ანგარიშდება სერვისის თანხა?</h4>
                <p>
                    სერვისის თანხა ანგარიშდება დოკუმენტის სირთულიდან და მოცულობიდან გამომდინარე, შეგიძლიათ ისარგებლოთ
                    როგორც სტანდარტული ასევე დაჩქარებული ტარიფით. დაჩქარებული ტარიფის შემთხვევაში დოკუმენტის სტანდარტულ
                    ფასს ემატება 4 ლარი.
                    სხვადასხვა ენაზე განსხვავებული ტარიფი მოქმედებს, რომელიც გადახდამდე შეგიძლიათ შეამოწმოთ ფასის
                    მაჩვენებელში.
                </p>
                <img className="faqPriceImg" src={priceImg} alt=""/>
            </Toggler>
            <Toggler eventKey={4}>
                <h4>შემიძლია თუ არა დასკანერებული დოკუმენტის ატვირთვა?</h4>
                <p>
                    დიახ, ჩვენთან შესაძლებელია დასკანერებული დოკუმენტის ატვირთვაც. ამ შემთხვევაში საფასური გამოითვლება
                    გვერდების რაოდენობის მიხედვით.
                </p>
            </Toggler>
            <Toggler eventKey={4}>
                <h4>რა ვადებში ხდება დოკუმენტის თარგმნა?</h4>

                <Table striped bordered size="sm">
                    <thead>
                    <tr>
                        <th>გვერდი</th>
                        <th>სტანდარტული</th>
                        <th>დაჩქარებული</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>0-5</td>
                        <td>1 დღე</td>
                        <td>3 სთ</td>
                    </tr>
                    <tr>
                        <td>5-10</td>
                        <td>1.5 დღე</td>
                        <td>5 სთ</td>
                    </tr>
                    <tr>
                        <td>10-15</td>
                        <td>2 დღე</td>
                        <td>7 სთ</td>
                    </tr>
                    <tr>
                        <td>15-20</td>
                        <td>2,5 დღე</td>
                        <td>10 სთ</td>
                    </tr>
                    <tr>
                        <td>20-25</td>
                        <td>3 დღე</td>
                        <td>12 სთ</td>
                    </tr>
                    {" "}
                    <tr>
                        <td>25-30</td>
                        <td>3.5 დღე</td>
                        <td>1 დღე</td>
                    </tr>
                    <tr>
                        <td>30-35</td>
                        <td>4 დღე</td>
                        <td>1.5 დღე</td>
                    </tr>
                    <tr>
                        <td>35-40</td>
                        <td>4.5 დღე</td>
                        <td>2 დღე</td>
                    </tr>
                    <tr>
                        <td>40-45</td>
                        <td>5 დღე</td>
                        <td>2.5 დღე</td>
                    </tr>
                    <tr>
                        <td>45-50</td>
                        <td>5.5 დღე</td>
                        <td>3 დღე</td>
                    </tr>
                    </tbody>
                </Table>
            </Toggler>
            <Toggler eventKey={5}>
                <h4>რა არის მომსახურების საფასური?</h4>
                <p>მომსახურების საფასური ითვლება დოკუმენტის სირთულიდან და მოცულობიდან გამომდინარე. დაჩქარებული სერვისის
                    შემთხვევაში საფასურს ემატება 4 ლარი.
                    გთავაზობთ მაგალითს ინგლისურ ენაზე ერთი გვერდის სხვადასხვა სირთულის ტექსტის თარგმნის შემთხვევაში:
                </p>
                <Table striped bordered size="sm">
                    <thead>
                    <tr>
                        <th>ინგლისური</th>
                        <th>სტანდარტული</th>
                        <th>დაჩქარებული</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>იურიდიული</td>
                        <td>20</td>
                        <td>24</td>
                    </tr>
                    <tr>
                        <td>ტექნიკური</td>
                        <td>20</td>
                        <td>24</td>
                    </tr>
                    <tr>
                        <td>სამედიცინო (ქართ-ინგ)</td>
                        <td>20</td>
                        <td>24</td>
                    </tr>
                    <tr>
                        <td>სამედიცინო (ინგ-ქართ)</td>
                        <td>24</td>
                        <td>28</td>
                    </tr>
                    <tr>
                        <td>მინდობილობა</td>
                        <td>30</td>
                        <td>34</td>
                    </tr>
                    {" "}
                    <tr>
                        <td>თანხმობა</td>
                        <td>20</td>
                        <td>24</td>
                    </tr>
                    <tr>
                        <td>ამონაწერი</td>
                        <td>20</td>
                        <td>24</td>
                    </tr>
                    <tr>
                        <td>მოწმობები/ცნობები</td>
                        <td>10</td>
                        <td>14</td>
                    </tr>
                    <tr>
                        <td>ფორმა 100</td>
                        <td>30</td>
                        <td>34</td>
                    </tr>
                    </tbody>
                </Table>

            </Toggler>
            <Toggler eventKey={6}>
                <h4>როგორ ვიხდი მომსახურების საფასურს?</h4>
                <p>
                    საფასურის გადახდა ხორციელდება ონლაინ რეჟიმში, შეკვეთის გაფორმების შემდეგ.
                </p>
            </Toggler>
            <Toggler eventKey={7}>
                <h4>შესაძლებელია თუ არა ინვოისით გადახდა?</h4>
                <p>
                    დიახ, ჩვენი გუნდი მომსახურებას უწევს როგორც ფიზიკურ, ისე იურიდიულ პირებს. შესაბამისად, შესაძლებელია
                    საფასურის ინვოისით გადახდა.
                </p>
            </Toggler>
            <Toggler eventKey={8}>
                <h4>
                    შემექმნა პრობლემა დოკუმენტის ატვირთვაზე/შეკვეთის განხორციელებაზე
                </h4>
                <p>
                    მსგავსი შეფერხების შემთხვევაში მოგვმართეთ ელ.ფოსტაზე: online@translate.ge . ჩვენი გუნდი ეცდება
                    მაქსიმალურად მოკლე დროში გაგიწიოთ დახმარება.
                </p>
            </Toggler>

            <Toggler eventKey={9}>
                <h4>გაქვთ თუ არა თარგმანის ნოტარიულად დამოწმების სერვისი?</h4>
                <p>
                    დიახ, გარდა იმისა რომ თქვენს დოკუმენტს პროფესიონალი თარჯიმნები თარგმნიან, ჩვენ ასევე დაგეხმარებით
                    თარგმნილი დოკუმენტის ნოტარიულად დამოწმებაშიც. რაც მთავარია, ეს მომსახურებაც არის ონლაინ რეჟიმში და
                    თქვენ სახლიდან გაუსვლელად შეძლებთ მიიღოთ დამოწმებული დოკუმენტი.
                </p>
            </Toggler>
            <Toggler eventKey={10}>
                <h4>როგორ მივიღებ ნოტარიულად დამოწმებულ დოკუმენტს?</h4>
                <p>
                    ნოტარიულად დამოწმებულ დოკუმენტს სურვილის შემთხვევაში კურიერი თარგმანის დასრულებიდან მეორე დღესვე
                    მოგაწვდით მითითებულ მისამართზე.
                    საკურიერო მომსახურების საფასური თბილისის მასშტაბით არის 5 ლარი.

                </p>
            </Toggler>
            <Toggler eventKey={11}>
                <h4>რა ღირს დოკუმენტის ნოტარიულად დამოწმება?</h4>
                <p>
                    დოკუმენტის ნოტარიულად დამოწმებაზე ჩვენთან მოქმედებს სტანდარტული ფასები.</p>
                <ul>
                    <li>• ერთი გვერდის საფასური - 5ლ</li>
                    <li>• ყოველი მომდევნო გვერდი - 2ლ</li>
                </ul>

            </Toggler>
            <Toggler eventKey={12}>
                <h4>როგორ გავხდე online.translate.ge-ს პარტნიორი თარჯიმანი?</h4>
                <p>
                    ჩვენ მზად ვართ ვითანამშრომლოთ თარჯიმნებთან.თუ გსურთ შემოუერთდეთ ჩვენს გუნდს გამოგვიგზავნეთ თქვენი
                    რეზიუმე online@translate.ge -ზე.
                </p>
            </Toggler>
        </Container>
    );
};

export default FAQ;
