import React from "react";

const File = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Capa_1"
        x="0px"
        y="0px"
        viewBox="0 0 367.6 367.6"
        fill="currentColor"
        width="2.3rem"
        className="icon-upload"
      >
        <g>
          <g>
            <g>
              <path d="M328.6,81.6c-0.4,0-0.4-0.4-0.8-0.8c-0.4-0.4-0.4-0.8-0.8-1.2L258.2,2.4c-0.4-0.4-1.2-0.8-1.6-1.2     c-0.4,0-0.4-0.4-0.8-0.4c-0.8-0.4-2-0.8-3.2-0.8H83.8C59,0,38.6,20.4,38.6,45.2v277.2c0,24.8,20.4,45.2,45.2,45.2h200     c24.8,0,45.2-20.4,45.2-45.2v-238C329,83.6,328.6,82.4,328.6,81.6z M260.2,27.2l44.4,50h-44.4V27.2z M313.8,322     c0,16.8-13.2,30.4-30,30.4h-200c-16.8,0-30-13.6-30-30V44.8c0-16.8,13.6-30,30-30H245v69.6c0,4,3.2,7.6,7.6,7.6h61.2V322z" />
              <path d="M92.6,92h66.8c4,0,7.6-3.2,7.6-7.6s-3.2-7.6-7.6-7.6H92.6c-4,0-7.6,3.2-7.6,7.6S88.6,92,92.6,92z" />
              <path d="M159.4,275.6H92.6c-4,0-7.6,3.2-7.6,7.6c0,4,3.2,7.6,7.6,7.6h66.8c4,0,7.6-3.2,7.6-7.6     C167,279.2,163.4,275.6,159.4,275.6z" />
              <path d="M85,134.8c0,4,3.2,7.6,7.6,7.6H271c4,0,7.6-3.2,7.6-7.6c0-4-3.2-7.6-7.6-7.6H92.6C88.6,127.2,85,130.4,85,134.8z" />
              <path d="M271,164.8H92.6c-4,0-7.6,3.2-7.6,7.6c0,4,3.2,7.6,7.6,7.6H271c4,0,7.6-3.2,7.6-7.6C278.6,168,275.4,164.8,271,164.8z" />
              <path d="M271,202H92.6c-4,0-7.6,3.2-7.6,7.6c0,4,3.2,7.6,7.6,7.6H271c4,0,7.6-3.2,7.6-7.6C278.6,205.2,275.4,202,271,202z" />
              <path d="M271,239.2H92.6c-4,0-7.6,3.2-7.6,7.6c0,4,3.2,7.6,7.6,7.6H271c4,0,7.6-3.2,7.6-7.6C278.6,242.8,275.4,239.2,271,239.2z" />
            </g>
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    </>
  );
};
export default File;
