import React, { useContext } from "react";
import Context from "../../../context/Context";
import OrderCard from "../OrderCard";
import Information from "../Information";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const AdditionalServices = props => {

    const context = useContext(Context);
    const { additionalServiceHandler } = context.handlers;
    let { additionalService } = context.globalstate;


    const controlCheckbox = () => {
        props.checkboxRef.current.checked = !props.checkboxRef.current.checked;
        if (additionalService === true) {
            props.checkboxRef.current.checked = false;
            props.checkboxCourier.current.checked = false;
        }
    };

    const controlCourierCheckbox = () => {
        if (additionalService === true) {
            props.checkboxCourier.current.checked = false;
        }
    };

    return (
        <Row>
            <Col>
                <fieldset disabled={props.disabledForm}>
                    {/* <fieldset> */}
                    <OrderCard title="თარგმანის ნოტარიულად დამოწმება" number="3">
                        <Information>
                            შეგიძლიათ ისარგებლოთ თარგმანის ნოტარიულად დამოწმებისა და ადგილზე
                            მიწოდების სერვისით. პროფესიონალი თარჯიმანი თარგმნის თქვენს
                            დოკუმენტს, უზრუნველყოფს თარგმანის ნოტარიულად დამოწმებას და ჩვენი
                            საკურიერო სამსახური სასურველ მისამართზე მოგართმევთ დამოწმებულ
                            დოკუმენტს
                        </Information>
                        <div>
                            <input
                                type="checkbox"
                                ref={props.checkboxRef}
                                onClick={() => {
                                    additionalServiceHandler();
                                    controlCourierCheckbox();
                                }}
                            />{" "}
                            <span className="notary" onClick={() => {
                                additionalServiceHandler();
                                controlCheckbox();
                            }}>მსურს თარგმანის ნოტარიულად დამოწმება</span>

                        </div>
                    </OrderCard>
                </fieldset>
            </Col>
        </Row>
    );
};

export default AdditionalServices;
