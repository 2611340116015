import React from "react";
const AdsInfo = () => {
  return(
    <div className="ads-info">
     <span>
       ზომა 55kb
     </span>
     <span>
       ფორმატი JPG ან HTML5
     </span>
    </div>
  )
};

export default AdsInfo;
