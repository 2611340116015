import React, { useContext } from "react";
import Context from "../../../context/Context";
import OrderCard from "../OrderCard";
import Information from "../Information";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useToasts } from "react-toast-notifications";

const Duration = props => {
    const context = useContext(Context);
    const { addToast } = useToasts();
    const { passingDate } = context.globalstate;
    const { getTimeByHour } = context.handlers;

    const durationError = () => {
        if (getTimeByHour() === 3) {
            addToast("სერვისი ხელმისაწვდომია 15 საათამდე", {
                appearance: "error",
                autoDismiss: true
            });
        }
    };

    return (
        <Row>
            <Col>
                <fieldset>
                    <OrderCard title="მიწოდების ხანგრძლივობა" number="4">
                        <Information>
                            შეგიძლიათ ისარგებლოთ თარგმანის სტანდარტული ან დაჩქარებული სერვისებით.
                            პერიოდი განისაზღვრება სათარგმნი დოკუმენტის სიდიდიდან გამომდინარე.
                            დაჩქარებული სერვისით სარგებლობის შემთხვევაში გთხოვთ დოკუმენტი ატვირთოთ 15:00 საათამდე.
                        </Information>
                        <Row>
                            <Col>
                                <button
                                    name="slow"
                                    onClick={props.passingDateHandler}
                                    className={passingDate === 'slow' ? 'grouped-btn highlight' : 'grouped-btn'}
                                >
                                    სტანდარტული
                                </button>
                            </Col>
{/* 
                            <Col>

                                <button
                                    disabled={getTimeByHour() === 3}
                                    name="fast"
                                    onClick={props.passingDateHandler}
                                    className={passingDate === 'fast' ? 'grouped-btn highlight' : 'grouped-btn'}
                                >
                                    {getTimeByHour() === 3 ?
                                        <span onClick={() => durationError()}>დაჩქარებული</span> : 'დაჩქარებული'}
                                </button>

                            </Col> */}
                        </Row>
                    </OrderCard>
                </fieldset>
            </Col>
        </Row>
    );
};

export default Duration;
