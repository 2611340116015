import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import OrderPage from "./components/OrderPage/OrderPage";
import OrderSubmit from './components/OrderPage/OrderSubmit';
import NotFound from './components/NotFound';
import Terms from './components/Terms';
import Price from './components/Price';
import AppProvider from './context/Provider';
import { ToastProvider } from 'react-toast-notifications'
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Background from "./components/Background/Background";
import Advertisement from "./components/Advertisement/Advertisement";
import FAQ from "./components/FAQ/FAQ";
import Footer from "./components/Footer/Footer";

// React Google Analytics code react-ga
import ReactGA from 'react-ga';

// ReactGA.initialize('UA-157589153-1');
ReactGA.initialize('UA-173630048-1');
ReactGA.pageview(window.location.pathname + window.location.search);



function App() {
  return (
    <div className="App">
      <Router>
        <AppProvider>
        <ToastProvider>
            <Container>
              <Header />
            </Container>
            <Background>
              <Switch>
                <Route exact path="/" component={OrderPage} />
                {/* <Route exact path="/order" component={OrderPage} /> */}
                <Route exact path="/order-summary" component={OrderSubmit} />
                <Route exact path="/terms" component={Terms} />
                <Route exact path="/price" component={Price} />
                <Route exact path="/ads" component={Advertisement} />
                <Route exact path="/FAQ" component={FAQ}/>
                <Route path="*" component={NotFound} />
              </Switch>
            </Background>
            <Footer/>
            </ToastProvider>
        </AppProvider>
      </Router>
    </div>
  );
}

export default App;
