import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Context from "../../context/Context";
import OrderCard from "./OrderCard";
import Information from "./Information";
import OrderInfo from "./OrderInfo";

const OrderSubmit = props => {
    const context = useContext(Context);
    let history = useHistory();

    /*eslint-disable */
    const {
        nameChangeHandler,
        emailChangeHandler,
        phoneChangeHandler,
        addressChangeHandler,
        additionalAddressChangeHandler,
        cityChangeHandler,
        commentHandler
    } = context.handlers;

    const {
        name,
        email,
        address,
        additionalAddress,
        city,
        phone,
        additionalService,
        projectuid,
        notaryFileToTranslate
    } = context.globalstate;
    /*eslint-disable */

    useEffect(() => {
        if (!projectuid && !notaryFileToTranslate) {
            history.push("/");
        }
    }, []);


    return (
        <section className="order-summary-section">
            <fieldset disabled={props.disabledCard}>
                <OrderCard title="საკონტაქტო ინფორმაცია" number="7">
                    <Information placement="left">
                        შეიყვანეთ სწორი მონაცემები, მითითებულ მეილზე მიიღებთ ინფორმაციას
                        თარგმნის შესახებ
                    </Information>
                    <input
                        autoComplete="on"
                        name="name"
                        type="text"
                        onChange={nameChangeHandler}
                        className="order-input"
                        placeholder="თქვენი სახელი და გვარი"
                        value={name}
                    />
                    <input
                        autoComplete="on"
                        name="email"
                        type="email"
                        onChange={emailChangeHandler}
                        className="order-input"
                        placeholder="თქვენი ელ. ფოსტა"
                        value={email}
                    />
                    <input
                        autoComplete="on"
                        name="phone"
                        type="tel"
                        onChange={phoneChangeHandler}
                        className="order-input"
                        placeholder="თქვენი მობილურის ნომერი"
                        value={phone}
                    />
                    {additionalService ? (
                        <>
                            <input
                                type="text"
                                onChange={addressChangeHandler}
                                className="order-input"
                                placeholder="თქვენი მისამართი"
                                value={address}
                            />
                            <input
                                type="text"
                                onChange={additionalAddressChangeHandler}
                                className="order-input"
                                placeholder="თქვენი მისამართი (დამატებითი ინფორმაცია)"
                                value={additionalAddress}
                            />
                        </>
                    ) : null}
                </OrderCard>
            </fieldset>

            <fieldset disabled={props.disabledCard}>
                <OrderCard title="კომენტარი" number="8">
                    <Information>დაგვიტოვეთ დამატებითი კომენტარი</Information>
                    <textarea
                        className="comment-textarea"
                        onChange={commentHandler}
                        placeholder="შეგიძლიათ, დატოვოთ დამატებითი კომენტარი"
                    ></textarea>
                </OrderCard>
            </fieldset>
            <OrderInfo/>
        </section>
    );
};

export default OrderSubmit;
