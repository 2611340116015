import React from "react";

const Judge = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon"
        version="1.1"
        viewBox="0 0 512 512.0001"
        fill="currentColor"
        width="1.5em"
      >
        <g id="surface1">
          <path d="M 15 512 L 287 512 C 295.285156 512 302 505.285156 302 497 L 302 481 C 302 464.429688 288.570312 451 272 451 L 272 433.066406 C 272 409.320312 252.679688 390 228.933594 390 L 73.066406 390 C 49.320312 390 30 409.320312 30 433.066406 L 30 451 C 13.429688 451 0 464.429688 0 481 L 0 497 C 0 505.28125 6.71875 512 15 512 Z M 15 512 " />
          <path d="M 187.734375 330.34375 C 197.703125 343.574219 216.515625 346.21875 229.746094 336.246094 C 242.980469 326.277344 245.621094 307.464844 235.652344 294.230469 L 145.378906 174.4375 C 135.40625 161.203125 116.597656 158.5625 103.367188 168.53125 C 90.132812 178.503906 87.488281 197.3125 97.460938 210.546875 Z M 187.734375 330.34375 " />
          <path d="M 403.363281 167.851562 C 413.335938 181.082031 432.144531 183.726562 445.378906 173.753906 C 458.609375 163.785156 461.253906 144.976562 451.28125 131.742188 L 361.007812 11.949219 C 351.039062 -1.285156 332.226562 -3.929688 318.996094 6.042969 C 305.765625 16.015625 303.121094 34.824219 313.09375 48.058594 Z M 403.363281 167.851562 " />
          <path d="M 337.5625 255 C 359.734375 238.292969 376.378906 217.546875 386.789062 195.703125 L 281.75 56.3125 C 257.882812 60.300781 233.355469 70.578125 211.179688 87.289062 C 189.007812 103.996094 172.363281 124.742188 161.953125 146.582031 L 266.992188 285.980469 C 290.859375 281.992188 315.386719 271.710938 337.5625 255 Z M 337.5625 255 " />
          <path d="M 378.066406 258.90625 C 371.082031 266.023438 363.726562 272.847656 355.617188 278.960938 C 347.503906 285.070312 338.914062 290.261719 330.152344 295.015625 L 349.710938 320.972656 L 397.628906 284.867188 Z M 378.066406 258.90625 " />
          <path d="M 415.683594 308.824219 L 367.765625 344.929688 L 458.039062 464.726562 C 468.011719 477.960938 486.820312 480.601562 500.050781 470.632812 C 513.285156 460.660156 515.925781 441.851562 505.957031 428.617188 Z M 415.683594 308.824219 " />
        </g>
      </svg>
    </>
  );
};

export default Judge;
